import { ErrorData } from "types/error";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {FetchParameters, HttpRequestParameters} from "types/http";
import axiosInstance from "./axios";
import {API} from "../constants";

const fetchData = async ({method, url, data, onSuccess, onError, onDone}: FetchParameters) => {

    let request = data ? {method, url, data} : {method, url};

    return await axiosInstance(request).then((res) => {
        onSuccess && onSuccess(res.data);
    }).catch(error => {
        onError && onError(error);
        console.log(error.response.data.message);
        throw new Error(error.response.data.message ?? 'Une erreur a été rencontré.');
    }).finally(() => onDone && onDone());
}
const get = (params: FetchParameters, pendingMessage="", successMessage="", notify= true) => {
    if(!notify) {
        fetchData(params)
    } else {
        toast.promise(
            fetchData(params),
            {
                pending: pendingMessage || 'Fetching data...',
                success: successMessage || 'Data fetched successfully',
                error: {
                    render : ({data}: ErrorData) => data?.message
                },
            },
        );
    }
}

const performRequest = ({uri, method, onSuccess, data, pendingMessage, successMessage, notify = false}: HttpRequestParameters ) => {
    let params: FetchParameters = {
        method: method,
        url: `${API()}${uri}`,
        onSuccess: onSuccess,
        data: data
    };

    get(params, pendingMessage, successMessage, notify)
}

export { get, performRequest }