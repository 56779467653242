import LoginFooter from "main/login/LoginFooter";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import LoginForm from "./LoginForm";
import { UserCredentials } from "types/user";
import { useAuthenticationHooks } from "hooks/AuthenticationHooks";
import { ToastContainer } from "react-toastify";
import ShapeGradient from "main/shared/common/ShapeGradient";

const Login = () => {
const {authenticate} = useAuthenticationHooks()

const login = (credentials: UserCredentials, onDone: Function): void => {
    authenticate(credentials, onDone)
}

return (
    <>
    <main>
        <section className="section section-shaped">
            <ShapeGradient/>
            <Container className="py-7">
                <Row className="justify-content-center">
                    <Col lg="5" md="7">
                        <Card className="bg-secondary shadow border-0">
                            <CardHeader className="bg-transparent p-0">
                            <div className="text-muted text-center mt-2">
                                <h3>Bienvenue sur <strong>SBS</strong></h3>
                            </div>
                                <div className="btn-wrapper text-center">
                                    <img alt="..." src={require("assets/img/sbs-logo-1.png")} style={{width: '40%'}}/>
                                </div>
                            </CardHeader>
                            <CardBody className="px-5">
                                <LoginForm onSubmit={login}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    </main>
    <LoginFooter />
    <ToastContainer style={{ width: "500px" }}/>
    </>
);
};


export default Login