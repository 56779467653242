import { API } from "constants/index";
import { endpoints } from "constants/endpoints";
import { AuthenticationContext } from "context/AuthenticationContext";
import { useContext } from "react";
import { UserCredentials } from "types/user";
import { get } from "api/http";
import { FetchParameters } from "types/http";

export const useAuthentication = () => useContext(AuthenticationContext);

export const useAuthenticationHooks = () => {
    const {setUserSession} = useAuthentication();

    const pendingMessage: string = "Vérification des informations..."
    const successMessage: string = "Connexion réussi..."
    const notify: boolean = true;
    
    const authenticate = (credentials: UserCredentials, onDone: Function|null = null) => {
        let params: FetchParameters = {
            method: "post", 
            url: `${API()}${endpoints.authenticate}`,
            onSuccess: (response) => {
                setTimeout(() => {
                    setUserSession(response);
                    window.location.reload();
                }, 2000)
            },
            onDone,
            data: credentials,
        };

        get(params, pendingMessage, successMessage, notify)
    }

    const logout = () => {
        sessionStorage.clear();
        window.location.href = "/";
    }

    return {authenticate, logout};
}