import { Suspense } from "react";

/// Components
import { Navigate, Route, Routes } from "react-router-dom";

/// Style
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-dashboard-react.scss";


import { useAuthentication } from "../hooks/AuthenticationHooks";
import Login from "./login/Login";
import Index from "./index/Index";
import { ChangePassword } from "./change-password/ChangePassword";

const App = () => {
  const {isAuthenticated, isFirstLogin} = useAuthentication();

  console.log("isAuthenticated", isAuthenticated, "isFirstLogin", isFirstLogin);
  
  return (isAuthenticated && !isFirstLogin) ? (
    <Suspense
      fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
    >
      <Routes>
        <Route path="*" element={<Index />} />
      </Routes>
    </Suspense>
  ) : (isAuthenticated && isFirstLogin) ? (
    <div className="vh-100">
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<ChangePassword />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </div>
  ) : (
    <div className="vh-100">
    <Suspense
      fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  </div>
  );
};

export default App;