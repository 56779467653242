import React, { useState } from 'react';
import { Input, ListGroup, ListGroupItem } from 'reactstrap';
type Props = {
    name: string;
    suggestions: string[],
    placeholder?: string,
    onSuggestionSelected: (value: string) => void;
}

const AutocompleteInput = ({name, suggestions, placeholder, onSuggestionSelected, }: Props) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState('');

  const onChange = (e: any) => {
    const userInput = e.currentTarget.value;
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setFilteredSuggestions(filteredSuggestions);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
    setUserInput(e.currentTarget.value);
  };

  const onClick = (e: any) => {
    const selectedValue = e.currentTarget.innerText;
    setFilteredSuggestions([]);
    setUserInput(selectedValue);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    onSuggestionSelected(selectedValue);
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) { // Enter key
      const selectedValue = filteredSuggestions[activeSuggestionIndex];
      setUserInput(selectedValue);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
      onSuggestionSelected(selectedValue)
    } else if (e.keyCode === 38) { // Up arrow
      if (activeSuggestionIndex === 0) {
        return;
      }
      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    } else if (e.keyCode === 40) { // Down arrow
      if (activeSuggestionIndex === filteredSuggestions.length - 1) {
        return;
      }
      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  let suggestionsListComponent;

  if (showSuggestions && userInput) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ListGroup>
          {filteredSuggestions.map((suggestion, index) => {
            let className;
            if (index === activeSuggestionIndex) {
              className = "pointer active";
            }
            return (
              <ListGroupItem
                key={suggestion}
                className={className}
                onClick={onClick}
              >
                {suggestion}
              </ListGroupItem>
            );
          })}
        </ListGroup>
      );
    } else {
      suggestionsListComponent = (
        <div className="no-suggestions">
          <em>No value available</em>
        </div>
      );
    }
  }

  return (
    <div>
      <Input
        name={name}
        placeholder={placeholder}
        autoComplete="on"
        autoCorrect="on"
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={userInput}
      />
      {suggestionsListComponent}
    </div>
  );
};

export default AutocompleteInput;
