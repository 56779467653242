import { TranslationWords } from "types/words";

const words: TranslationWords = {
    "dashboard.projects": {
        "en": "Projects",
    },    
    "dashboard.projects.active": {
        "en": "Actives",
    },    
    "dashboard.projects.ongoing": {
        "en": "Ongoing",
    },    
    "dashboard.projects.delayed": {
        "en": "Delayed",
    },    
    "dashboard.projects.overdue": {
        "en": "Overdue",
    },    
    "dashboard.projects.suspended": {
        "en": "Suspended",
    },    
    "dashboard.projects.done": {
        "en": "Done",
    },    
    "dashboard.projects.clients": {
        "en": "Clients",
    },    
    "dashboard.projects.selled": {
        "en": "Selled",
    },    
    "dashboard.projects.workflows": {
        "en": "Workflows",
    },

    "dashboard.tasks": {
        "en": "Tasks",
    },    

    "dashboard.teams": {
        "en": "Team",
    },
    "dashboard.teams.present": {
        "en": "Present",
    },
    "dashboard.teams.inservice": {
        "en": "Out",
    },
    "dashboard.teams.onsite": {
        "en": "In",
    },
    "dashboard.teams.onLeaves": {
        "en": "Present",
    },

    "dashboard.clients": {
        "en": "Clients",
    },
    "dashboard.clients.actives": {
        "en": "Actives",
    },
    "dashboard.client.active": {
        "en": "Actives",
    },    
    "dashboard.client.ongoing": {
        "en": "Projects Ongoing",
    },    
    "dashboard.client.delayed": {
        "en": "Projects Delayed",
    },    
    "dashboard.clients.overdue": {
        "en": "Projects Overdue",
    },    
    "dashboard.clients.suspended": {
        "en": "Projects Suspended",
    },    
    "dashboard.clients.done": {
        "en": "Projects Done",
    },    
}

export default words;