import { Container, Row } from "reactstrap";
import { Users } from "./Users";
import Header from "./UserBoardFilter";
import { useUserHooks } from "hooks/UserHooks";
import { useEffect, useState } from "react";
import Pagination from "main/shared/controls/CustomPagniation";
import { UserFilterRequest } from "types/user";

export const UserBoard = () => {
  const { getAllUsers, filterUsers, users } = useUserHooks();
  const [filterRequest, setFilterRequest] = useState<UserFilterRequest>({});
  const [page, setPage] = useState<number>(0);
  const size: number = 10;
  const isFiltered = !!filterRequest.criteriaType && !!filterRequest.value; 


  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    isFiltered
    ? filterUsers(filterRequest, page, size)
    : getAllUsers(page, size);
  }, [page, filterRequest]);

  return (
    <>
      <Header users={users} onFilter={setFilterRequest} />
      <Container fluid>
        <Row>
          <div className="col py-4">
            <Users users={users?.content ?? []} />
          </div>
        </Row>
        <div className="d-flex justify-content-end">
          <Pagination
            currentPage={page}
            totalPages={users?.totalPages!}
            onChangePage={(page) => handlePageChange(page)}
          />
        </div>
      </Container>
    </>
  );
};
