import {useEffect, useState} from "react";
import {Button} from "reactstrap";
import Modal from "../shared/controls/CustomModal";
import {ToastContainer} from "react-toastify";
import {TOAST_WIDTH} from "../../constants/toast";
import { NewDepositForm } from "./NewDepositForm";
import { useUserHooks } from "hooks/UserHooks";
import { useDepositHooks } from "hooks/DepositHooks";
import { DepositSubmitRequest } from "types/deposit";

export const NewDeposit = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);
    const { getSellers, sellers} = useUserHooks();
    const { saveDeposit } = useDepositHooks();

    useEffect(() => {
        getSellers();
    }, []);

    const sendDeposit = (request: DepositSubmitRequest): void => {
        saveDeposit(request, toggleModal);
    }

    return (
        <>
            <div className="col text-right">
                <Button className="btn-sm" onClick={toggleModal}>
                    Nouveau déposit <i className="fa text-success fa-plus-circle" />
                </Button>
            </div>
            <Modal
                isOpen={isOpen}
                header="Nouveau déposit"
                body={
                    <div className="container">
                        <NewDepositForm onSubmit={sendDeposit} 
                        sellers={sellers ?? []}/>
                    </div>
                }
                toggle={toggleModal}
                size={"md"}
            />
            <ToastContainer style={{width: TOAST_WIDTH}}/>
        </>
    )
}