export type ProviderStatus = "ACTIVE" | "INACTIVE";

export type ProviderStatusFilter = 'ALL' | ProviderStatus;


export type Provider = {
    id: string;
    name: string;
    status: ProviderStatus;
    type: ProviderType
    icon?: string;
}

export type ProviderCreationRequest = {
    name?: string;
    type?: ProviderType;
    icon?: string;
}

export type ProviderUpdateRequest = {
    providerId: string;
    name?: string;
    icon?: string;
    type?: ProviderType;
}

export type ProviderChangeStatus = {
    providerId: string;
    status: ProviderStatus;
}
export function isActive(provider: Provider) {
    return provider.status === "ACTIVE";
}

export type ProviderType = 'TRANSFER' | 'PACKAGE' | 'INVOICE';

export type ProviderTypeFilter = 'ALL' | ProviderType;

export type ProviderFilterRequest = {
    criteriaType?: ProviderFilterCriteriaType;
    value?: string;
}

export type ProviderFilterCriteriaType = 'NAME' | 'TYPE' | 'STATUS';

export function getProviderBadgeColor(providerType: ProviderType) {
    switch (providerType) {
        case "TRANSFER" :
            return "success";
        case "PACKAGE" :
            return "primary";
        case "INVOICE" :
            return "default";
    }
}

export const providerTypes: Array<{label: string, value: ProviderType}> = [
    {label: "Transfert", value: "TRANSFER"},
    {label: "Crédit/Forfait", value: "PACKAGE"},
    {label: "Facture", value: "INVOICE"}
];

export function translateProviderName(providerType: ProviderType) {
    switch (providerType) {
        case "TRANSFER" :
            return "Transfert";
        case "PACKAGE" :
            return "Crédit /Forfait";
        case "INVOICE" :
            return "Facture";
    }
}

export const providerStatusFilterOptions: Array<{value: ProviderStatusFilter, label: string, className: string}> = [
    { value: "ALL", label: "Tout", className: "fa fa-arrow-up" },
    { value: "ACTIVE", label: "Active", className: "fa fa-arrow-up text-success" },
    { value:  "INACTIVE", label: "Inactive", className: "fa fa-arrow-down text-danger" },
];

export const providerTypeFilterOptions : Array<{value: ProviderTypeFilter, label: string, className: string}> = [
    { value: "ALL", label: "Tout", className: "fa fa-arrow-up" },
    { value: "TRANSFER", label: "Transfert", className: "fa fa-send text-success" },
    { value: "PACKAGE", label: "Crédit/Forfait", className: "fa fa-cube text-primary" },
    { value: "INVOICE", label: "Facture", className: "fa fa-file text-default" },
];