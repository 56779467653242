// reactstrap components
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row,} from "reactstrap";
import Select from "main/shared/controls/Select";
import {
  UserInfo,
  UserFilterRequest,
  UserRoleFilter,
  UserStatusFilter,
  userRoleFilterOptions,
  userStatusFilterOptions
} from "../../types/user";
import { Page } from "types/page";

type Props = {
  users?: Page<UserInfo>
  onFilter: (request: UserFilterRequest) => void
}

export const UserBoardFilter = ({users, onFilter}: Props) => {

  const filterByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 3) {
        return;
    }
    const request: UserFilterRequest = {
      criteriaType: "NAME",
      value: event.target.value
    }
    
    onFilter(request);
  }
  
  const filterByStatus = (status: UserStatusFilter) => {
    const request: UserFilterRequest = {
      criteriaType: "STATUS",
      value: status
    }

    onFilter(request);
  }
  
  const filterByRole = (role: UserRoleFilter) => {
    const request: UserFilterRequest = {
      criteriaType: "ROLE",
      value: role
    }
  
    onFilter(request);
  }

  return (
    <>
      <div className="header bg-gradient-info pb-3 pb-md-4 pt-4 pt-md-4">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col>
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col-12">
                        <Form>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <Label for="name" className="font-weight-bold">
                                  <i className="fa fa-edit" /> Rechercher par nom
                                </Label>
                                <Input
                                    placeholder="Enter le nom"
                                    id="name"
                                    isMulti={true}
                                    type="text"
                                    onChange={filterByName}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label for="userStatus" className="font-weight-bold">
                                  <i className="fa fa-level-up" />Status
                                </Label>
                                <Select name="userStatus"
                                        id="userStatus"
                                        isMulti={false}
                                        data={userStatusFilterOptions.map((status) => {
                                          return {
                                            value: status.value,
                                            label: (
                                                <span>
                                                  <i className={status.className} /> {status.label}
                                                </span>
                                            ),
                                          };
                                        })}
                                        onChangeHandler={(e, a) => {
                                          filterByStatus(e.value)
                                        }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label for="userStatus" className="font-weight-bold">
                                  <i className="fa fa-user-secret" />Role
                                </Label>
                                <Select name="userRole"
                                        id="userRole"
                                        isMulti={false}
                                        data={userRoleFilterOptions.map((role) => {
                                          return {
                                            value: role.value,
                                            label: (
                                                <span>
                                                  <i className={role.className} /> {role.label}
                                                </span>
                                            ),
                                          };
                                        })}
                                        onChangeHandler={(e, a) => {
                                          filterByRole(e.value)
                                        }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <p className="text-right text-primary">
                            <span>Total utilisateurs : {users?.totalElements ?? 0}</span>
                          </p>
                        </Form>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
        </Container>
      </div>
    </>
  );
};

export default UserBoardFilter