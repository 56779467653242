import { NewService } from "main/provider-service/NewService"
import {  Card, CardHeader, Row, Table } from "reactstrap"
import { Deposit, getDepositStatusTextColor, isSent, translateDepositStatus } from "types/deposit"
import { CancelDeposit } from "./CancelDeposit";
import { NewDeposit } from "./NewDeposit";
import { Page } from "types/page";
import { TransformUtils } from "main/utils/transformUtils";

type Props = {
    deposits: Deposit[];
}

export const Deposits = ({deposits}: Props) => {
    return (
        <>
            <Card className="shadow">
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Tous les déposits</h3>
                        </div>
                        <div className="col text-right">
                            <NewDeposit/>
                        </div>
                    </Row>

                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th scope="col" className="">Date</th>
                        <th scope="col" className="">Vendeur</th>
                        <th scope="col" className="">Numéro</th>
                        <th scope="col" className="">Montant</th>
                        <th scope="col" className="">Status</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {deposits?.map((deposit) => (
                            <tr key={deposit.id}>
                                <th scope="row">
                                    {TransformUtils.getDate(deposit.date!)}
                                </th>
                                <td>{deposit.firstname} {deposit.lastname}</td>
                                <td><a className="text-primary pointer">{deposit.phoneNumber}</a></td>
                                <th scope="row">{deposit.amount}</th>
                                <td className={getDepositStatusTextColor(deposit.status)}>{translateDepositStatus(deposit.status)}</td>
                                {
                                    isSent(deposit) && 
                                    <td>
                                    <CancelDeposit deposit={deposit}/>
                                  </td>
                                }
                            </tr>
                        )
                    )}
                    </tbody>
                </Table>
            </Card>
        </>
    )
}