import {Button, Table} from "reactstrap";
import {isActive, ServiceChangeStatus, ServiceStatus} from "../../types/service";
import { useProviderHooks } from "hooks/ProviderHooks";
import { useEffect } from "react";
import { DEFAULT_IMAGE } from "constants/index";

type Props = {
    toggleModal: () => void;
};

export const Services = ({toggleModal}: Props) => {
    const {getAllServices, servicesLight, setServiceStatus} = useProviderHooks();

    useEffect(() => {
        getAllServices();
    }, []);

    
    const setStatus = (serviceId: string, status: ServiceStatus): void => {
        const request: ServiceChangeStatus = {
            serviceId: serviceId,
            status: status
        }
        
       setServiceStatus(request, toggleModal);
    }

    return (
        <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
            <tr>
                <th scope="col">Nom</th>
                <th scope="col">Action</th>
            </tr>
            </thead>
            <tbody>
            {
                servicesLight.map((service) => (
                    <tr key={service.id}>
                        <th scope="row">
                            <div className="d-flex align-items-center">
                                 <span className="avatar avatar-sm rounded-circle">
                                     <img alt="Wave" src={service.providerIcon ?? DEFAULT_IMAGE}/>
                                </span>&nbsp;
                                <span>{service.name}</span>
                            </div>
                        </th>
                        <td className="text-default">
                            {
                                isActive(service.status) ?
                                    <Button
                                    color="danger"
                                    href="#pablo"
                                    onClick={() => setStatus(service.id, 'INACTIVE')}
                                    size="sm"
                                > Désactiver</Button>
                                    :   <Button
                                        color="success"
                                        href="#pablo"
                                        onClick={() => setStatus(service.id, 'ACTIVE')}
                                        size="sm"
                                    >Activer</Button>
                            }


                        </td>
                    </tr>
                ))
            }
            </tbody>
        </Table>
    )
}