import {useState} from "react";
import {Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row} from "reactstrap";
import {Service, ServiceUpdateRequest} from "../../types/service";
import {useParams} from "react-router";

type Props = {
    service: Service
    onSubmit: (request: ServiceUpdateRequest, toggleLoading: Function) => void;
}
export const EditServiceForm = ({service, onSubmit}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const toggleLoading = () => {
        setLoading(prev => !prev);
    }
    
    const [request, setRequest] = useState<ServiceUpdateRequest>({
        serviceId: service.id,
        name: service.name
    });

    const formIsValid = !!request.name &&  !!service.name;
    const disabled = !formIsValid || loading;

 
    const onFormChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;
        setRequest({...request, [name] : value });
    }
    const onFormSubmitted = () => {
        onSubmit(request, toggleLoading);
    }

    return (
        <Form role="form">
            <Row>
                <Col lg="10">
                    <FormGroup className="mb-3">
                        <Label className="font-weight-bold">Nom <span className="text-danger font-weight-bold">*</span></Label>
                        <InputGroup className="input-group-alternative">
                            <InputGroupText><i className="fa fa-edit" /></InputGroupText>
                            <Input placeholder="Renseigner le nom"
                                   type="text"
                                   name="name"
                                   required
                                   value={request.name}
                                   onChange={onFormChange}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
            <div className="text-center">
                <Button className="my-2 px-5" color="primary" type="button"
                        onClick={onFormSubmitted}
                        disabled={disabled}
                >Valider
                </Button>
            </div>
        </Form>
    );
}