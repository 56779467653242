import AutocompleteInput from "main/shared/controls/AutocompleteInput";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { DepositSubmitRequest } from "types/deposit";
import { Seller } from "types/seller";

type Props = {
  sellers: Seller[];
  onSubmit: (request: DepositSubmitRequest, toggleLoading: Function) => void;
};

export const NewDepositForm = ({ onSubmit, sellers }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [request, setRequest] = useState<DepositSubmitRequest>({});
  const [sellerName, setSellerName] = useState<string>("");

  const formIsValid = !!request.amount && !!request.sellerId;

  const disabled = !formIsValid || loading;

  const handleSellerSelected = (value: string) => {
    const seller = sellers.find((seller) => seller.phoneNumber === value);

    if (!!seller?.id) {
      setRequest({ ...request, sellerId: seller.id });

      setSellerName(`${seller?.firstname}-${seller?.lastname}`);
    }
  };
  
  const toggleLoading = () => {
    setLoading((prev) => !prev);
  };

  const onFormChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setRequest({ ...request, [name]: value });
  };

  const onFormSubmitted = () => {
    onSubmit(request, toggleLoading);
  };

  return (
    <Form role="form">
      <Row className="mb-3">
        <Col lg="12">
          <FormGroup className="mb-3">
            <Label className="font-weight-bold">
              Vendeur <span className="text-danger font-weight-bold">*</span>
            </Label>
            <InputGroup className="input-group-alternative">
              <InputGroupText>
                <i className="fa fa-phone font-weight-bold" />
              </InputGroupText>
              <AutocompleteInput
                name="sellerId"
                suggestions={sellers.map((s) => s.phoneNumber)}
                placeholder="Enter le numéro"
                onSuggestionSelected={handleSellerSelected}
              />
            </InputGroup>
          </FormGroup>
        </Col>

        {
            sellerName && (
                <Col lg="12">
                <FormGroup className="mb-3">
                  <Label className="font-weight-bold">
                    Nom complet <span className="text-danger font-weight-bold">*</span>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupText>
                      <i className="fa fa-user" />
                    </InputGroupText>
                    <Input
                      type="text"
                      name="sellerName"
                      value={sellerName}
                      required
                      disabled
                      readOnly
                      onChange={onFormChange}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            )
        }
        
        <Col lg="12">
          <FormGroup className="mb-3">
            <Label className="font-weight-bold">
              Montant <span className="text-danger font-weight-bold">*</span>
            </Label>
            <InputGroup className="input-group-alternative">
              <InputGroupText>
                <i className="fa fa-money font-weight-bold" />
              </InputGroupText>
              <Input
                placeholder="Renseigner le montant"
                type="number"
                name="amount"
                min={1}
                value={request.amount}
                required
                onChange={onFormChange}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button
          className="my-4 px-5"
          color="primary"
          type="button"
          onClick={onFormSubmitted}
          disabled={disabled}
        >
          Valider
        </Button>
      </div>
    </Form>
  );
};
