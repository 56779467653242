import { useTransactionHooks } from "hooks/TransactionHooks";
import Header from "./TransactionBoardFilter";
import { Transactions } from "./Transactions";
import { Container, Row } from "reactstrap";
import { useEffect, useState } from "react";
import Pagination from "../shared/controls/CustomPagniation";
import { useUserHooks } from "hooks/UserHooks";
import { TransactionFilterRequest } from "types/transaction";
import tokenService from "services/token-service";
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import { HOST } from "constants/index";
import { endpoints } from "constants/endpoints";

export const TransactionBoard = () => {
  const { getAllTransactions, filterTransactions, transactions } =
    useTransactionHooks();
    
  const [isNewTransaction, setIsNewTransaction] = useState<boolean>(false);
  const { getSellers, sellers } = useUserHooks();
  const [filterRequest, setFilterRequest] = useState<TransactionFilterRequest>({});
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const isFiltered = !!filterRequest.criteriaType && !!filterRequest.value; 

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
      isFiltered
      ? filterTransactions(filterRequest, page, size)
      : getAllTransactions(page, size);
  }, [page, filterRequest]);

  useEffect(() => {
    getSellers();
  }, []);

  useEffect(() => {
    handleNewTransaction();
  }, [isNewTransaction]);

  const handleNewTransaction = () => {
    const token = tokenService.getToken();

    const socket = new SockJS(`${HOST}/ws-bo`);

    const stompClient = new Client({
    //  debug: (str) => console.log("STOMP: " + str),
      webSocketFactory: () => socket,

      connectHeaders: {
        Authorization: `Bearer ${token}`,
      },

      onConnect: () => {
        console.log("Connected to WebSocket");
        stompClient.subscribe(endpoints.newTransaction, (message: any) => {
          console.log("Subscribe to transaction");
          console.log("Received New Transaction:", JSON.parse(message.body));

          setIsNewTransaction(true);

          getAllTransactions(page, size);
        });

      },

      onDisconnect: () => {
        console.log("Disconnected from WebSocket");
      },

      onStompError: (frame: any) => {
        console.error("Broker reported error: " + frame.headers["message"]);
        console.error("Additional details: " + frame.body);
      },

      onWebSocketClose: () => {
        console.log("WebSocket closed, attempting to reconnect...");
        setTimeout(() => {
          handleNewTransaction();
        }, 10000);
      },
    });

    stompClient.activate();

    return () => {
      stompClient.deactivate();
    };
  };

  return (
    <>
      <Header
        transactions={transactions}
        sellers={sellers ?? []}
        onFilter={setFilterRequest}
      />
      <Container fluid>
        <Row>
          <div className="col py-4">
            <Transactions transactions={transactions?.content ?? []} />
          </div>
        </Row>
        <div className="d-flex justify-content-end">
          <Pagination
            currentPage={page}
            totalPages={transactions?.totalPages!}
            onChangePage={(page) => handlePageChange(page)}
          />
        </div>
      </Container>
    </>
  );
};
