import LoginFooter from "main/login/LoginFooter";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import {TOAST_WIDTH} from "../../constants/toast";
import ShapeGradient from "main/shared/common/ShapeGradient";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { useUserHooks } from "hooks/UserHooks";
import { ChangePasswordRequest } from "types/user";

export const ChangePassword = () => {
    const { changePassword } = useUserHooks();

    const validate = (request: ChangePasswordRequest,onDone: Function): void => {
        changePassword(request, onDone)
    }

    return (
        <>
        <main>
            <section className="section section-shaped">
                <ShapeGradient/>
                <Container className="py-lg-3">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7">
                            <Card className="bg-secondary shadow border-0">
                                <CardHeader className="bg-transparent p-1">
                                <div className="text-muted text-center mt-2">
                                <h3><strong>Changement de mot de passe</strong></h3>
                                </div>
                                <div className="btn-wrapper text-center">
                                    <img alt="..." src={require("assets/img/sbs-logo-1.png")} style={{width: '40%'}}/>
                                </div>
                                </CardHeader>
                                <CardBody className="px-lg-5">
                                <h4 className="text-muted mb-3">Entrer votre nouveau mot de passe ...</h4>
                                <ChangePasswordForm onSubmit={validate}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
        <LoginFooter />
        <ToastContainer style={{ width: TOAST_WIDTH }}/>
        </>
    );
};