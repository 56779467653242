import { countries } from "constants/countries";

export type UserRole = "SUPER_ADMIN" | "ADMIN" | "SELLER";

export type UserStatus = "ACTIVE" | "INACTIVE" | "DELETED" | "SUSPENDED";

export type UserInfo = {
  id?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  phoneNumber?: string;
  address: string;
  role?: UserRole;
  status?: UserStatus;
  createdAt?: string;
  accountId: string;
};

export type UserSession = {
  token: string;
};

export type UserCredentials = {
  username: string;
  password: string;
};

export function isActive(status: UserStatus) {
  return status === "ACTIVE";
}

export type UserCreationRequest = {
  firstname?: string;
  lastname?: string;
  email?: string;
  phoneNumber?: string;
  role?: UserRole;
  address?: string;
};

export type UserUpdateRequest = {
  userId: string;
  firstname: string;
  lastname: string;
  phoneNumber: string;
  role: UserRole;
  address?: string;
};

export type UserChangeStatusRequest = {
  userId: string;
  status: UserStatus;
};

export const userRoles: Array<{ label: string; value: UserRole }> = [
  { label: "Super admin", value: "SUPER_ADMIN" },
  { label: "Admin", value: "ADMIN" },
  { label: "Vendeur", value: "SELLER" },
];

export type UserToken = {
  sub?: string;
  uuid?: string;
  userId?: string;
  username?: string;
  role: UserRole;
  isFirstLogin: boolean,
  exp?: number;
  iat?: number;
  jti?: string;
};

export type UserFilterRequest = {
  criteriaType?: UserFilterCriteriaType;
  value?: string;
};

export type UserFilterCriteriaType = "NAME" | "STATUS" | "ROLE";

export type UserStatusFilter = "ALL" | UserStatus;

export type UserRoleFilter = "ALL" | UserRole;

export type ResetPasswordRequest = {
  accountId: string;
};

export type ChangePasswordRequest = {
  newPassword: string;
  confirmPassword: string
}

export function translateUserRole(role: UserRole) {
  switch (role) {
    case "SUPER_ADMIN":
      return "Super admin";
    case "ADMIN":
      return "Admin";
    case "SELLER":
      return "Vendeur";
    default:
      return "";
  }
}

export function isSeller(role: UserRole) {
  return role === "SELLER";
}

export function isDeleted(user: UserInfo) {
  return user.status === "DELETED";
}

export function getUserStatusTextMessage(status: UserStatus) {
  switch (status) {
    case "ACTIVE":
      return "activé";
    case "INACTIVE":
      return "désactivé";
  }
}



export const userStatusFilterOptions: Array<{value: UserStatusFilter, label: string, className: string}> = [
  { value: "ALL", label: "Tout", className: "fa fa-arrow-up text-default" },
  { value: "ACTIVE", label: "Active", className: "fa fa-arrow-up text-success" },
  { value: "INACTIVE", label: "Inactive", className: "fa fa-arrow-down text-warning" },
  { value: "SUSPENDED", label: "Suspendu", className: "fa fa-arrow-down text-danger" },
  { value: "DELETED", label: "Supprimé", className: "fa fa-arrow-down text-danger fw-bold" },
];

export const userRoleFilterOptions: Array<{value: UserRoleFilter, label: string, className: string}> = [
  { value: "SUPER_ADMIN", label: "Super admin", className: "fa fa-user-secret text-success" },
  { value: "ADMIN", label: "Admin", className: "fa fa-user-secret text-primary" },
  { value: "SELLER", label: "Vendeur", className: "fa  fa-user-secret text-warning" },
];