// reactstrap components
import Select, {ActionMeta} from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";

type Props = {
    data: Array<{ value: any; label: any }>;
    name: string;
    isMulti: boolean;
    id: string;
    value?: any;
    onChangeHandler?: (
        option: any,
        actionMeta: ActionMeta<{ value: any; label: any }>
    ) => void;
    creatable?: boolean;
    defaultValue?: any
    required?: boolean;
};
const CustomSelect = ({
                          name,
                          data,
                          isMulti,
                          id,
                          value,
                          onChangeHandler,
                          creatable = false,
                          defaultValue,
                          required = false
                      }: Props) => {
    const animatedComponents = makeAnimated();

    return creatable ? (
        <CreatableSelect
            name={name}
            isMulti={isMulti}
            options={data}
            id={id}
            closeMenuOnSelect={true}
            components={animatedComponents}
            value={value}
            required={required}
            onChange={onChangeHandler}
            defaultValue={""}
        />
    ) : (
        <Select
            name={name}
            isMulti={isMulti}
            options={data}
            id={id}
            closeMenuOnSelect={true}
            components={animatedComponents}
            value={value}
            onChange={onChangeHandler}
            defaultValue={"defaultValue"}
        />
    );
};


export default CustomSelect;
