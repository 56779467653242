import {useState} from "react";
import {Provider, providerTypes, ProviderUpdateRequest} from "../../types/provider";
import {Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row} from "reactstrap";
import {InputFile} from "../shared/controls/InputFile";
import Select from "../shared/controls/Select";

type Props = {
    provider: Provider
    onSubmit: (request: ProviderUpdateRequest, toggleLoading: Function) => void;
}
export const EditProviderForm = ({provider, onSubmit}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [request, setRequest] = useState<ProviderUpdateRequest>({
        providerId: provider.id,
        name: provider.name,
        type: provider.type
    });

    const [imageLoaded, setImageLoaded] = useState<boolean>(false);

    const toggleLoading = () => {
        setLoading(prev => !prev);
    }

    const handleImageChange = (data: string) => {
        if(data) {
            setImageLoaded(true);
            setRequest({...request, icon: data});
        }
    }
    
    const onFormChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;
        setRequest({...request, [name] : value });
    }

    const onFormSubmitted = () => {
        onSubmit(request, toggleLoading);
    }

    return (
        <Form role="form">
            <Row>
                <Col lg="10">
                    <FormGroup className="mb-3">
                        <Label className="font-weight-bold">Nom <span className="text-danger font-weight-bold">*</span></Label>
                        <InputGroup className="input-group-alternative">
                            <InputGroupText><i className="fa fa-edit" /></InputGroupText>
                            <Input placeholder="Renseigner le nom"
                                   type="text"
                                   name="name"
                                   required
                                   value={request.name}
                                   onChange={onFormChange}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="10">
                    <Label className="font-weight-bold">Type <span className="text-danger font-weight-bold">*</span></Label>
                    <Select name="type"
                            id="type"
                            isMulti={false}
                            value={providerTypes.find(p => p.value === request.type)}
                            data={providerTypes}
                            onChangeHandler={(e, a) => {
                                setRequest({...request, type: e.value});
                            }}
                    />
                </Col>
            </Row>
            <Row className="my-3 align-items-center">
                <Col md="10">
                    <InputFile label="Icone"
                               fileType="image/png, image/jpeg"
                               onFileChange={handleImageChange}/>
                </Col>
                {
                    (provider.icon || imageLoaded) &&
                    <Col md="2" className="mt-4">
                        <span className="avatar avatar-sm rounded-circle">
                          <img alt="" src={!imageLoaded ? provider.icon : request.icon}/>
                      </span>
                    </Col>
                }
            </Row>

            <div className="text-center">
                <Button className="my-2 px-5" color="primary" type="button"
                        onClick={onFormSubmitted}
                >Valider
                </Button>
            </div>
        </Form>
    );
}