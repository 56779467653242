import { Fragment, ReactNode, useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { GroupRouteParamsType, RouteParams, SingleRouteParams, SingleRouteParamsType } from "types/route";
import {useAuthenticationHooks} from "../../../hooks/AuthenticationHooks";

type Props = {
  routes: Array<RouteParams>,
  logo: {
    innerLink?: string,
    outterLink?: string,
    imgAlt?: string,
    imgSrc?: string
  }
};

const Sidebar = ({routes, logo}: Props) => {
  const {logout} = useAuthenticationHooks();

  const [collapseOpen, setCollapseOpen] = useState<boolean>(false);
  // // verifies if routeName is the one active (in browser input)
  // const activeRoute = (routeName) => {
  //   return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  // };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes: Array<RouteParams>) => {

    const renderNavItem = (item: SingleRouteParams, key: number) => {
      return (
        <NavItem key={key}>
          <NavLink to={item.path} tag={NavLinkRRD} onClick={closeCollapse} className="text-white m-0 p-1 text-sm">
            <i className={`${item.icon}`} /> <strong>{item.name}</strong>
          </NavLink>
        </NavItem>
    )}

    const renderNav = (name: string, key: number, renderFn: (key: number) => ReactNode) => {
      return (
        <Fragment key={key} >
          <h6 className="navbar-heading text-muted">{name}</h6>
          <Nav className="mb-4" navbar>{renderFn(key)}</Nav>
        </Fragment>
      )
    }

    return routes.map((params, key1) => {
      if(params.tag === SingleRouteParamsType){
        return renderNav(params.name, key1, (key) => renderNavItem(params, key))
      }else if(params.tag === GroupRouteParamsType){
        const items = params.items;
        const groupName = params.groupName;
        return renderNav(groupName, key1, (key) => items && items
          .filter(item => !!item.menu)
          .map((item, key2) => renderNavItem(item, key*10+key2))
        )
      }
    });
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = { to: logo.innerLink, tag: Link };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = { href: logo.outterLink, target: "_blank" };
  }

  return (
    <Navbar className="navbar-vertical fixed-left navbar-light bg-default" expand="md" id="sidenav-main">
      <Container fluid>
        {/* Toggler */}
        <button className="navbar-toggler" type="button" onClick={toggleCollapse} >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        <NavbarBrand className="py-0 px-2 mt-2 bg-white rounded-pill" {...navbarBrandProps}>
            <h1 style={{fontFamily: 'Oxygen'}} className="text-default display-4 my-0">SBS <i className="fa fa-money "/></h1>
        </NavbarBrand>
        {/* <a href="#">
          <img alt="..." src={require("assets/img/sbs-logo-1.png")} style={{width: '100%'}} />
        </a> */}

        {/* User */}
        <Nav className="align-items-center d-md-none text-white">
          <UncontrolledDropdown>
            <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55 text-white" />
            </DropdownToggle>
            <DropdownMenu aria-labelledby="navbar-default_dropdown_1" className="dropdown-menu-arrow" right>
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img alt="..." src={require("assets/img/sbs-logo-1.png")} />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Bienvenue!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>Mon profile</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-settings-gear-65" />
                <span>Paramètres</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-calendar-grid-58" />
                <span>Activité</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-support-16" />
                <span>Support</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={logout}>
                <i className="ni ni-user-run" />
                <span>Se déconnecter</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo && (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}> <img alt={logo.imgAlt} src={logo.imgSrc} /></Link>
                  ) : (
                    <a href={logo.outterLink}> <img alt={logo.imgAlt} src={logo.imgSrc} /></a>
                  )}
                </Col>
              )}
              <Col className="collapse-close" xs="6">
                <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input aria-label="Search" className="form-control-rounded form-control-prepended" placeholder="Search"type="search" />
                <InputGroupText><span className="fa fa-search" /></InputGroupText>
            </InputGroup>
          </Form>
          <>{createLinks(routes)}</>
          <hr className="my-3 bg-white" />
          <h6 className="navbar-heading text-muted">
            © {new Date().getFullYear()}{" "}
            <a href="https://www.creative-tim.com?ref=adsr-footer" target="_blank">SBS</a>
            .
          </h6>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;