import {Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import Select from "../shared/controls/Select";
import {ProviderStatus} from "../../types/provider";
import {Service, ServiceFilterRequest, ServiceStatusFilter, serviceStatusFilterOptions} from "../../types/service";

type Props = {
    services: Service[],
    onFilter: (request: ServiceFilterRequest) => void
}

const ServiceBoarFilter = ({services, onFilter}: Props) => {

    const filterByName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 3) {
            return;
        }
    
        const filter: ServiceFilterRequest = {
          criteriaType: "NAME",
          value: event.target.value
        }
    
        onFilter(filter);
    }
    
    const filterByStatus = (status: ProviderStatus) => {
        const request: ServiceFilterRequest = {
          criteriaType: "STATUS",
          value: status
        }

        onFilter(request);
    }
    
    return (
        <>
            <div className="header bg-gradient-info pb-3 pb-md-4 pt-4 pt-md-4">
                <Container fluid>
                    <div className="header-body">
                        {/* Card stats */}
                        <Row>
                            <Col>
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col-12">
                                                <Form>
                                                    <Row>
                                                        <Col md="4">
                                                            <FormGroup>
                                                                <Label for="name" className="font-weight-bold">
                                                                    <i className="fa fa-edit" /> Rechercher par nom
                                                                </Label>
                                                                <Input
                                                                    placeholder="Enter le nom"
                                                                    id="name"
                                                                    isMulti={true}
                                                                    type="text"
                                                                    onChange={(e) => filterByName(e)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="4">
                                                            <FormGroup>
                                                                <Label for="status" className="font-weight-bold">
                                                                    <i className="fa fa-level-up" />Status
                                                                </Label>
                                                                <Select name="status"
                                                                        id="status"
                                                                        isMulti={false}
                                                                        data={serviceStatusFilterOptions.map((status) => {
                                                                            return {
                                                                                value: status.value,
                                                                                label: (
                                                                                    <span>
                                                                                      <i className={status.className} /> {status.label}
                                                                                    </span>
                                                                                ),
                                                                            };
                                                                        })}
                                                                        onChangeHandler={(e, a) => filterByStatus(e.value)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <p className="text-right text-primary">
                                                        <span>Total services: {services?.length ?? 0}</span>
                                                    </p>
                                                </Form>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default ServiceBoarFilter;