import axios from "axios";
import TokenService from "../services/token-service";
import { API } from "../constants";
import { endpoints } from "../constants/endpoints";

const axiosInstance = axios.create({
  baseURL: API(),
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = TokenService.getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    const tokenExpired = originalConfig?.url !== endpoints.authenticate
      && err?.response?.status === 401
      && !originalConfig._retry;

    if (tokenExpired) {
      originalConfig._retry = true;
      TokenService.setUserToken(null);
      window.location.replace("/");
    }

    return Promise.reject(err);
  }
);

export default axiosInstance;
