import {FormGroup, Input, InputGroup, InputGroupText, Label} from "reactstrap";

type Props = {
    label: string;
    fileType: string;
    onFileChange : (image: string) => void;
}
export const InputFile = ({label, fileType, onFileChange}: Props) => {
    const loadPicture = (data: any) => {
        let file = data[0];
      //  if (file?.type.includes(fileType)) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                onFileChange(reader.result?.toString()!);
            }
       // }
    }

    return (
        <FormGroup>
            <Label for="image" className="h5">
                { label }
            </Label>
            <InputGroup className="input-group-alternative">
                <InputGroupText>
                    <i className="fa fa-file"/>
                </InputGroupText>
                <Input
                    placeholder="file"
                    type="file"
                    id="image"
                    accept={fileType}
                    onChange={(e) => {
                        loadPicture(e.target.files)
                    }}
                />
            </InputGroup>
        </FormGroup>
    )
}