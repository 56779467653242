import { Container, Row } from "reactstrap";
import Header from "./DepositBoardFilter";
import { Deposits } from "./Deposits";
import { useDepositHooks } from "hooks/DepositHooks";
import { useEffect, useState } from "react";
import Pagination from "../shared/controls/CustomPagniation";
import { DepositFilterRequest } from "types/deposit";

export const DepositBoard = () => {
  const { getAllDeposits, filterDeposits, deposits } = useDepositHooks();
  const [filterRequest, setFilterRequest] = useState<DepositFilterRequest>({});
  const isFilted = !!filterRequest.criteriaType && !!filterRequest.value;
  const [page, setPage] = useState<number>(0);
  const size = 5;

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
      isFilted
      ? filterDeposits(filterRequest, page, size) 
      : getAllDeposits(page, size);
  }, [page, filterRequest]);

  return (
    <>
      <Header deposits={deposits} onFilter={setFilterRequest} />
      <Container fluid>
        <Row>
          <div className="col py-4">
            <Deposits deposits={deposits?.content ?? []} />
          </div>
        </Row>

        <div className="d-flex justify-content-end">
          <Pagination
            currentPage={page}
            totalPages={deposits?.totalPages!}
            onChangePage={(page) => handlePageChange(page)}
          />
        </div>
      </Container>
    </>
  );
};
