import {endpoints} from "constants/endpoints";
import {performRequest} from "api/http";
import {HttpRequestParameters} from "types/http";
import { useState} from "react";
import {reloadWindows} from "../constants";
import {PENDING_MESSAGE} from "../constants/toast";
import { Page } from "types/page";
import { Transaction, TransactionCancelRequest, TransactionFilterRequest, TransactionTreatRequest } from "types/transaction";

export const useTransactionHooks = () => {
    const [transactions, setTransactions] = useState<Page<Transaction>>();
    const [isLoading, setIsLoading] = useState(false);

    const getAllTransactions = (page: number, size: number) => {
        setIsLoading(true);

        let params: HttpRequestParameters = {
            uri: endpoints.transactions.concat("?page=", page.toString(), "&size=", size.toString()),
            method: "get",
            onSuccess: (response: Page<Transaction>) => {
                setTransactions(response);
            },
            data: null,
            successMessage: "",
            pendingMessage: ""
        }

        performRequest(params);
    }

    const treatTransaction = (request: TransactionTreatRequest, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.transactions.concat("/treat"),
            method: "patch",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: "Transaction traité avec succès",
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    const cancelTransaction = (request: TransactionCancelRequest, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.transactions.concat("/cancel"),
            method: "patch",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: "Transaction annulé avec succès",
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    const filterTransactions = (request: TransactionFilterRequest, page: number, size: number) => {
        setIsLoading(true);

        if (request.criteriaType === "STATUS" && request.value === "ALL") {
            getAllTransactions(page, size);
            return;
        }

        if (request.criteriaType === "SIZE") {
            getAllTransactions(page, parseInt(request.value!));
            return;
        }

        let params: HttpRequestParameters = {
            uri: endpoints.transactions.concat("/filter?page=", page.toString(), "&size=", size.toString()),
            method: "post",
            onSuccess: (response: Page<Transaction>) => {
                setTransactions(response);
            },
            data: request,
            successMessage: "",
            pendingMessage: "",
        }

        performRequest(params);
    }

    return {
        isLoading,
        getAllTransactions,
        transactions,
        treatTransaction,
        cancelTransaction,
        filterTransactions
    };
}