import Header from "./ServiceBoardFilter";
import {Container, Row} from "reactstrap";
import {Services} from "./Services";
import {ServiceFilterRequest} from "../../types/service";
import { useProviderHooks } from "hooks/ProviderHooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

export const ServiceBoard = () => {
    const {providerId} = useParams();
    const {getAllServicesByProvider, services, filterServices, servicesFiltered} = useProviderHooks();
    const [filterRequest, setFilterRequest] = useState<ServiceFilterRequest>({});
    const isFilted = !!filterRequest.criteriaType && !!filterRequest.value;
  
    useEffect(() => {
        isFilted
        ? filterServices(filterRequest)
        : getAllServicesByProvider(providerId!);
    }, [filterRequest]);

    return (
        <>
            <Header services={isFilted ? servicesFiltered : services} onFilter={setFilterRequest}/>
            <Container fluid>
                <Row>
                    <div className="col py-4">
                        <Services services={isFilted ? servicesFiltered : services}/>
                    </div>
                </Row>
            </Container>
        </>
    )
}