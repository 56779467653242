import {Badge, Card, CardHeader, Media, Row, Table, UncontrolledTooltip} from "reactstrap"
import {TransformUtils} from "../utils/transformUtils";
import {isActive, Service} from "../../types/service";
import {NewService} from "./NewService";
import {EditService} from "./EditService";
import {ChangeServiceStatus} from "./ChangeServiceStatus";
import { DEFAULT_IMAGE } from "constants/index";

type Props = {
    services: Service[];
}

export const Services = ({services}: Props) => {
    return (
        <>
            <Card className="shadow">
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Tous les services</h3>
                        </div>
                        <div className="col text-right">
                            <NewService/>
                        </div>
                    </Row>

                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th scope="col" className="">Nom</th>
                        <th scope="col" className="">Status</th>
                        <th scope="col" className="">Date de création</th>
                        <th scope="col" className=""></th>
                    </tr>
                    </thead>
                    <tbody>
                    {services.map((service) => (
                            <tr key={service.id}>
                                <th scope="row">
                                    <div className="d-flex align-items-center">
                                                <span className="avatar avatar-sm rounded-circle">
                                                    <img alt={service.name}
                                                         src={service.providerIcon ?? DEFAULT_IMAGE} />
                                                </span>&nbsp;
                                        <span>{service.name}</span>
                                    </div>
                                </th>
                                <td>
                                    <Badge className="text-white" color={isActive(service.status) ? 'success' : 'danger'}>
                                        {service.status}
                                    </Badge>
                                </td>
                                <td>{TransformUtils.getDate(service.createdAt)}</td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <EditService service={service}/>
                                        <ChangeServiceStatus service={service}/>
                                        {/* <Delêteservice service={service}/> */}
                                    </div>
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </Table>
            </Card>
        </>
    )
}