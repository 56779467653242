import { Dispatch, SetStateAction, createContext, ReactNode, useState } from 'react';
import TokenService from "../services/token-service";
import {UserSession} from "../types/user";

type AuthContextValues = {
    userSession: UserSession;
    setUserSession: Dispatch<SetStateAction<UserSession>>;
    isAuthenticated: boolean;
    isFirstLogin: boolean;
}

type Props = {
    children: ReactNode;
};

let authContextDefaultValues: AuthContextValues = {
    userSession: {token: ''},
    setUserSession: () => {},
    isAuthenticated: false,
    isFirstLogin: false
}

export const AuthenticationContext= createContext(authContextDefaultValues);

export const AuthProvider = ({ children } : Props) => {
    const [userSession, setUserSession] = useState<UserSession>(authContextDefaultValues.userSession);

    const value = {
        userSession,
        setUserSession,
        isAuthenticated: TokenService.isAuthenticate(),
        isFirstLogin: TokenService.isFirstLogin()
    }

    TokenService.setUserToken(userSession?.token);

    return (
        <AuthenticationContext.Provider value={value}>
            {children}
        </AuthenticationContext.Provider>
    )
}