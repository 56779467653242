import {
  Button,
  Collapse,
  Row,
} from "reactstrap";
import Modal from "../shared/controls/CustomModal";
import { ToastContainer } from "react-toastify";
import { TOAST_WIDTH } from "../../constants/toast";
import { useState } from "react";
import {
  getTransactionButtonColor,
  Transaction,
  TransactionCancelRequest,
  TransactionTreatRequest,
  translateTransactionStatus,
} from "../../types/transaction";
import { useTransactionHooks } from "hooks/TransactionHooks";
import tokenService from "services/token-service";
import { DEFAULT_IMAGE } from "constants/index";
import { SellerDetails } from "./SellerDetails";
import { ClientDetails } from "./ClientDetails";

type Props = {
  transaction: Transaction;
};
export const TransactionDetails = ({ transaction }: Props) => {
  const [isOpenCollapseFrom, setIsOpenCollapseFrom] = useState(true);
  const [isOpenCollapseTo, setIsOpenCollapseTo] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  const toggleCollapseFrom = () => {
    setIsOpenCollapseFrom((prev) => !prev);
  };

  const toggleCollapseTo = () => {
    setIsOpenCollapseTo((prev) => !prev);
  };

  const { treatTransaction, cancelTransaction } = useTransactionHooks();

  const treat = () => {
    const request: TransactionTreatRequest = {
      transactionId: transaction.id!,
      treatedBy: tokenService.userId,
    };

    treatTransaction(request, toggleModal);
  };

  const cancel = () => {
    const request: TransactionCancelRequest = {
      transactionId: transaction.id!,
      canceledBy: tokenService.userId,
    };

    cancelTransaction(request, toggleModal);
  };

  return (
    <>
      <Button
        color={getTransactionButtonColor(transaction.status!)}
        onClick={toggleModal}
        size="sm"
      >
        {translateTransactionStatus(transaction.status!)}
      </Button>
      <Modal
        isOpen={isOpen}
        header={
          <div className="d-flex align-items-center">
            <span className="avatar avatar-sm rounded-circle">
              <img
                alt={transaction.service!.name}
                src={transaction.service!.icon ?? DEFAULT_IMAGE}
              />
            </span>
            &nbsp;
            <span>{transaction.service!.name}</span>
          </div>
        }
        body={
          <div className="container">
            <Row className="align-items-center">
              <div className="col bg-success">
                <h4 className="text-white pt-3">
                  {isOpenCollapseFrom ? (
                    <i
                      className="fa fa-chevron-up"
                      onClick={toggleCollapseFrom}
                    />
                  ) : (
                    <i
                      className="fa fa-chevron-down"
                      onClick={toggleCollapseFrom}
                    />
                  )}
                  &nbsp; Information du vendeur
                </h4>
              </div>
              <div className="col-12 mt-3" id="transaction-to">
                <Collapse isOpen={isOpenCollapseFrom}>
                    <SellerDetails transaction={transaction} />
                </Collapse>
              </div>
            </Row>

            <Row className="align-items-center mt-3">
              <div className="col bg-primary">
                <h4 className="text-white pt-3">
                  {isOpenCollapseTo ? (
                    <i
                      className="fa fa-chevron-up"
                      onClick={toggleCollapseTo}
                    />
                  ) : (
                    <i
                      className="fa fa-chevron-down"
                      onClick={toggleCollapseTo}
                    />
                  )}
                  &nbsp; Information du destinataire
                </h4>
              </div>

              <div className="col-12 mt-3" id="transaction-to">
                <Collapse isOpen={isOpenCollapseTo}>
                  <ClientDetails transaction={transaction}
                                 treatTransaction={treat}
                                 cancelTransaction={cancel}/>
                </Collapse>
              </div>
            </Row>
          </div>
        }
        toggle={toggleModal}
        size={"lg"}
      />
      <ToastContainer style={{ width: TOAST_WIDTH }} />
    </>
  );
};
