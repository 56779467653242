import { GroupRouteParamsType, RouteParams, SingleRouteParamsType } from "types/route";
import {TransactionBoard} from "./transaction/TransactionBoard";
import {UserBoard} from "./users/UserBoard";
import {ProviderBoard} from "./provider/ProviderBoard";
import {ServiceBoard} from "./provider-service/ServiceBoard";
import { DepositBoard } from "./deposit/DepositBoard";
import { ChangePassword } from "./change-password/ChangePassword";
import { useAuthentication } from "hooks/AuthenticationHooks";

export const SUPER_ADMIN_ROUTES: Array<RouteParams> = [

  // {
  //   tag: SingleRouteParamsType,
  //   path: "/",
  //   name: "Dashboard",
  //   icon: "fa fa-dashboard",
  //   component: <Dashboard />,
  //   layout: true,
  //   menu: true,
  // },
  {
    tag: SingleRouteParamsType,
    path: "/",
    name: "Transactions",
    icon: "fa fa-money",
    component: <TransactionBoard />,
    layout: true,
    menu: true,
  },
  {
    tag: SingleRouteParamsType,
    path: "/deposits",
    name: "Déposits",
    icon: "fa fa-money",
    component: <DepositBoard />,
    layout: true,
    menu: true,
  },
  {
    tag: GroupRouteParamsType,
    groupName: "Configurations",
    items: [
      {
        tag: SingleRouteParamsType,
        path: "/providers",
        name: "Gestion des opérateurs",
        icon: "fa fa-gear",
        component: <ProviderBoard />,
        layout: true,
        menu: true,
      },
      {
        tag: SingleRouteParamsType,
        path: "/providers/services/:providerId",
        name: "Gestion des services",
        icon: "fa fa-gear",
        component: <ServiceBoard />,
        layout: true,
        menu: false,
      },
    ]
  },
  {
    tag: SingleRouteParamsType,
    path: "/users",
    name: "Gestion utilisateurs",
    icon: "fa fa-users",
    component: <UserBoard />,
    layout: true,
    menu: true,
  },
];

export const ADMIN_ROUTES: Array<RouteParams> = [
  // {
  //   tag: SingleRouteParamsType,
  //   path: "/",
  //   name: "Dashboard",
  //   icon: "fa fa-dashboard",
  //   component: <Dashboard />,
  //   layout: true,
  //   menu: true,
  // },
  {
    tag: SingleRouteParamsType,
    path: "/",
    name: "Transactions",
    icon: "fa fa-money",
    component: <TransactionBoard />,
    layout: true,
    menu: true,
  }
];
