import { ReactNode } from "react";

export type SingleRouteParams = {
    tag: "single",
    path: string,
    name: string,
    icon?: string,
    component: ReactNode,
    layout: boolean,
    menu?: boolean
} 

export type GroupRouteParams = {
    tag: "group",
    groupName: string,
    items: Array<SingleRouteParams>,
    menu?: boolean
} 

export type RouteParams = SingleRouteParams | GroupRouteParams; 

export const SingleRouteParamsType = "single";
export const GroupRouteParamsType = "group";