import { TransformUtils } from "main/utils/transformUtils";
import { Media, Table } from "reactstrap";
import { Transaction } from "types/transaction";

type Props = {
  transaction: Transaction
}
export const SellerDetails = ({transaction}: Props) => {
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="">
          <tr key={transaction.seller!.id}>
            <th scope="col" className="">
              Vendeur
            </th>
            <th scope="col" className="">
              Email
            </th>
            <th scope="col" className="">
              Soumis le
            </th>
             <th scope="col" className="">
              N° Téléphone
            </th>
          </tr>
        </thead>
        <tbody>
          <tr key={""}>
            <th scope="row">
              <Media className="align-items-center">
                <span className="avatar rounded-circle mr-2 bg-success text-sm">
                  <span className="text-white text-sm">
                    {TransformUtils.getInitialName(
                      transaction.seller!.firstname!,
                      transaction.seller!.lastname!
                    )}
                  </span>
                </span>
                <Media>
                  <span className="mb-0 text-sm">
                    {transaction.seller!.firstname} {transaction.seller!.lastname}
                  </span>
                </Media>
              </Media>
            </th>
            <th scope="row">{transaction.seller!.email}</th>
            <th scope="row">{TransformUtils.getDate(transaction.createdAt!)}</th>
            <th scope="row">{transaction.seller!.phoneNumber}</th>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
