import { ToastContainer } from "react-toastify";
import Modal from "../shared/controls/CustomModal";

import UserEditForm from "./UserEditForm";
import { TOAST_WIDTH } from "../../constants/toast";
import { UserInfo, UserUpdateRequest } from "../../types/user";
import { useUserHooks } from "hooks/UserHooks";
import { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

type Props = {
  user: UserInfo
};

const UserEdit = ({ user }: Props) => {
  const { updateUser } = useUserHooks();
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const update = (request: UserUpdateRequest): void => {
    updateUser(request, toggleModal);
  };

  return (
    <>
      <div>
        <span id="edit-user">
            <i
            className="fa fa-edit text-sm text-primary pointer"
            onClick={toggleModal}
            ></i>
            <UncontrolledTooltip placement="bottom" target="edit-user">Modifier</UncontrolledTooltip>
        </span>
      </div>
      <Modal
        isOpen={isOpen}
        header="Modification des informations"
        body={
          <div className="container">
            <UserEditForm user={user} onSubmit={update} />
          </div>
        }
        toggle={toggleModal}
      />

      <ToastContainer style={{ width: TOAST_WIDTH }} />
    </>
  );
};

export default UserEdit;
