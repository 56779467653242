import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import HeaderNavbar from "../shared/layout/HeaderNavbar";
import Sidebar from "../shared/layout/Sidebar";
import { ADMIN_ROUTES, SUPER_ADMIN_ROUTES } from "../routes";
import { RouteParams, SingleRouteParamsType } from "types/route";
import tokenService from "services/token-service";
import { ChangePassword } from "main/change-password/ChangePassword";

const Index = () => {
//   const mainContent = React.useRef(null);
const location = useLocation();

const isSuperAdmin = tokenService.userRole === "SUPER_ADMIN";

const flatRoutes = (routes: Array<RouteParams>) => routes.flatMap(route => {
  if(route.tag === SingleRouteParamsType){
    return route;
  }else{
    return route.items;
  }
})

//   useEffect(() => {
//     document.documentElement.scrollTop = 0;
//     document.scrollingElement.scrollTop = 0;
//     if(mainContent?.current){
//         mainContent.current.scrollTop = 0;
//     } 
//   }, [location]);

  const getRoutes = (routes: Array<RouteParams>) => {
    return flatRoutes(routes).map((param, key) => {
      return param.layout ? <Route path={param.path} element={param.component} key={key} /> : null;
    });
  };

  const getBrandText = () => {
    const allRoutes = flatRoutes(isSuperAdmin ? SUPER_ADMIN_ROUTES : ADMIN_ROUTES);

    return allRoutes.find(route => route.path === location.pathname)?.name ?? "Gestion des services";
  };

  return (
    <>
      <Sidebar {...{location}}
        routes={isSuperAdmin ? SUPER_ADMIN_ROUTES : ADMIN_ROUTES}
        logo={{ innerLink: "/", imgSrc: require('assets/img/sbs-logo-1.png'), imgAlt: "..." }}
      />
      <div className="main-content">
        <HeaderNavbar brand={getBrandText()} />
        <Routes>
          {getRoutes(isSuperAdmin ? SUPER_ADMIN_ROUTES : ADMIN_ROUTES)}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Container fluid>
        </Container>
      </div>
    </>
  );
};

export default Index;