export type Transaction = {
    id?: string;
    createdAt?: string;
    service?: ServiceInfo;
    client?: Client;
    amount?: string;
    status?: TransactionStatus;
    seller?: SellerInfo;
    treatedBy?: TreatedByInfo;
    updatedAt?: string;
}

export type TransactionStatus = 'COMPLETED' | 'PENDING' | 'CANCELED';
export type TransactionStatusFilter = 'ALL' | TransactionStatus;

export type Client = {
    firstname: string;
    lastname: string;
    phoneNumber: string;
    country: string;
}

export type SellerInfo = {
    id?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    phoneNumber?: string;
}

export type ServiceInfo = {
    id: string;
    name: string;
    icon: string
}

export type TreatedByInfo = {
    id: string;
    firstname: string;
    lastname: string;
}

export type TransactionTreatRequest = {
    transactionId: string;
    treatedBy: string;
}

export type TransactionCancelRequest = {
    transactionId: string;
    canceledBy: string;
}

export type TransactionFilterRequest = { 
    criteriaType?: TransactionFilterCriteriaType;
    value?: string;
}

export type TransactionFilterCriteriaType = 'CLIENT' | 'STATUS' | 'DATE' | 'SIZE';

export const transactionStatusFilterOptions: Array<{
    value: TransactionStatusFilter;
    label: string;
    className: string;
}> = [
    { value: "ALL", label: "Tout", className: "fa fa-arrow-up" },
    {
        value: "PENDING",
        label: "En attente",
        className: "fa fa-arrow-up text-primary",
    },
    {
        value: "COMPLETED",
        label: "Livré",
        className: "fa fa-arrow-up text-success",
    },
    {
        value: "CANCELED",
        label: "Annulé",
        className: "fa fa-arrow-down text-danger",
    },
];

export function getTransactionButtonColor(transactionStatus: TransactionStatus) {
    switch (transactionStatus) {
        case "COMPLETED" :
            return "success";
        case "PENDING":
            return "default";
        case "CANCELED":
            return "danger";
    }
}

export function translateTransactionStatus(transactionStatus: TransactionStatus) {
    switch (transactionStatus) {
        case "COMPLETED" :
            return "Déjà traité";
        case "PENDING":
            return "Traiter";
        case "CANCELED":
            return "Déjà annulé";
    }
}

export function isCompleted(transaction: Transaction) {
    return transaction.status === 'COMPLETED';
}


export function isPending(transaction: Transaction) {
    return transaction.status === 'PENDING';
}