import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

type Props = {
    currentPage: number;
    totalPages: number;
    onChangePage: (page: number) => void;
}
const CustomPagination = ({currentPage, totalPages, onChangePage}: Props) => {

  const pagninateNext = () => {
    if (currentPage >= totalPages - 1) {
        return;
    }

    onChangePage(currentPage + 1);
  }
  
  const pagninatePrevious = () => {
    if (currentPage <= 0) {
        return;
    }

    onChangePage(currentPage - 1);
  }

  return (
    <nav aria-label="Pagination">
      <ul className="pagination">
        <li className="page-item">
          <a className="page-link" onClick={pagninatePrevious} aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>

        {
            Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
              <PaginationItem key={page} active={page === currentPage + 1}>
                <PaginationLink onClick={() => onChangePage(page - 1)}>
                  {page}
                </PaginationLink>
              </PaginationItem>
            ))
        }
        
        <li className="page-item">
          <a className="page-link" onClick={pagninateNext} aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default CustomPagination;
