import {ToastContainer} from "react-toastify";
import Modal from '../shared/controls/CustomModal';
import {Button} from "reactstrap";
import {useState} from "react";
import {TOAST_WIDTH} from "../../constants/toast";
import { Deposit, DepositCancelRequest } from "types/deposit";
import { useDepositHooks } from "hooks/DepositHooks";

type Props = {
    deposit: Deposit,
}
export const CancelDeposit = ({deposit}: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);
    const {cancelDeposit} = useDepositHooks();

    const cancel = (): void =>  {
        const request: DepositCancelRequest = { depositId: deposit.id!  };
        cancelDeposit(request, toggleModal);
    }

    return (
        <>
            <i className="fa fa-remove text-danger pointer" onClick={toggleModal}></i>
            <Modal
                isOpen={isOpen}
                header={"Anuulation déposit"}
                body={
                    <div className="container">
                        <p>Vous êtes sur le point d'annuler cet déposit. Voulez vous vraiment continuer ?</p>
                        <div className="text-center">
                            <Button className="my-4" color="primary" type="button"
                                    onClick={cancel}>Valider</Button>
                        </div>
                    </div>
                }
                toggle={toggleModal}
            />

            <ToastContainer style={{width: TOAST_WIDTH}}/>
        </>
    )
}
