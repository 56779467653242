import {
  getProviderBadgeColor,
  Provider,
  ProviderUpdateRequest,
} from "../../types/provider";
import Modal from "../shared/controls/CustomModal";
import { useState } from "react";
import { EditProviderForm } from "./EditProviderForm";
import { ToastContainer } from "react-toastify";
import { TOAST_WIDTH } from "../../constants/toast";
import { useProviderHooks } from "hooks/ProviderHooks";
import { Badge } from "reactstrap";

type Props = {
  provider: Provider;
};
export const EditProvider = ({ provider }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const { updateProvider } = useProviderHooks();

  const update = (request: ProviderUpdateRequest) => {
    updateProvider(request, toggleModal);
  };

  return (
    <>
      <span className="text-sm mr-2">
        <Badge
          className="text-white"
          color={"primary"}
          onClick={toggleModal}
        >
          Modifier
        </Badge>
      </span>
      <Modal
        isOpen={isOpen}
        header="Modification opérateur"
        body={
          <div className="container">
            <EditProviderForm provider={provider} onSubmit={update} />
          </div>
        }
        toggle={toggleModal}
      />
      <ToastContainer style={{ width: TOAST_WIDTH }} />
    </>
  );
};
