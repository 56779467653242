import Header from "./ProviderBoardFilter";
import { Container, Row } from "reactstrap";
import { ProviderList } from "./ProviderList";
import { useProviderHooks } from "hooks/ProviderHooks";
import { useEffect, useState } from "react";
import { ProviderFilterRequest } from "types/provider";

export const ProviderBoard = () => {
  const { getAllProviders, providers, filterProviders, providersFiltered } = useProviderHooks();
  const [filterRequest, setFilterRequest] = useState<ProviderFilterRequest>({});
  const isFilted = !!filterRequest.criteriaType && !!filterRequest.value;

  useEffect(() => {
      isFilted
      ? filterProviders(filterRequest)
      : getAllProviders();

  }, [filterRequest]);

  return (
    <>
      <Header
        providers={isFilted ? providersFiltered : providers}
        onFilter={setFilterRequest}
      />
      <Container fluid>
        <Row>
          <div className="col py-4">
            <ProviderList providers={isFilted ? providersFiltered : providers} />
          </div>
        </Row>
      </Container>
    </>
  );
};
