import { ProviderCreationRequest, providerTypes } from "../../types/provider";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { InputFile } from "../shared/controls/InputFile";
import Select from "../shared/controls/Select";

type Props = {
  onSubmit: (request: ProviderCreationRequest, toggleLoading: Function) => void;
};
export const NewProviderForm = ({ onSubmit }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [request, setRequest] = useState<ProviderCreationRequest>({});
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const formIsValid = !!request.name && !!request.type;
  const disabled = !formIsValid || loading;

  const toggleLoading = () => {
    setLoading((prev) => !prev);
  };

  const onFormChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setRequest({ ...request, [name]: value });
  };

  const handleImageChange = (data: string) => {
    if (data) {
      setImageLoaded(true);
      setRequest({ ...request, icon: data });
    }
  };

  const onFormSubmitted = () => {
    onSubmit(request, toggleLoading);
  };

  return (
    <Form role="form">
      <Row className="mb-3">
        <Col lg="10">
          <FormGroup className="mb-3">
            <Label className="font-weight-bold">
              Nom <span className="text-danger font-weight-bold">*</span>
            </Label>
            <InputGroup className="input-group-alternative">
              <InputGroupText>
                <i className="fa fa-edit font-weight-bold" />
              </InputGroupText>
              <Input
                placeholder="Renseigner le nom"
                type="text"
                name="name"
                value={request.name}
                required
                onChange={onFormChange}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label>
              Type <span className="text-danger font-weight-bold">*</span>
            </Label>
            <Select
              name="type"
              id="type"
              isMulti={false}
              data={providerTypes}
              onChangeHandler={(e, a) => {
                setRequest({ ...request, type: e.value });
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3 align-items-center">
        <Col md="10">
          <InputFile
            label="Icone"
            fileType="image/png, image/jpeg"
            onFileChange={handleImageChange}
          />
        </Col>
        {imageLoaded && (
          <Col md="2" className="mt-4">
            <span className="avatar avatar-sm rounded-circle">
              <img alt="" src={request.icon} />
            </span>
          </Col>
        )}
      </Row>
      <div className="text-center">
        <Button
          className="my-4 px-5"
          color="primary"
          type="button"
          onClick={onFormSubmitted}
          disabled={disabled}
        >
          Ajouter
        </Button>
      </div>
    </Form>
  );
};
