import Modal from '../shared/controls/CustomModal';
import {Button} from "reactstrap";
import {useState} from "react";
import {isActive, Service, ServiceChangeStatus} from "../../types/service";
import { useProviderHooks } from 'hooks/ProviderHooks';

type Props = {
    service: Service,
}
export const ChangeServiceStatus =  ({service}: Props) =>  {
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);

    const { setServiceStatus } = useProviderHooks();
    
    const setStatus = (): void => {

        const request: ServiceChangeStatus = {
            serviceId: service.id!,
            status: isActive(service.status) ? 'INACTIVE' : 'ACTIVE'
        }
        setServiceStatus(request, toggleModal);
    }

    return (
        <>
            <i className={`pointer text-sm ${!isActive(service.status) 
                ? 'fa fa-check text-success' : 
                'fa fa-exclamation text-warning'}`} onClick={toggleModal}></i>
            <Modal
                isOpen={isOpen}
                header={!isActive(service.status) ? 'Activation service ' : 'Désactivation service ' + service.name}
                body={
                    <div className="container">
                        <p>Vous êtes sur le point {!isActive(service.status) ? 'd\'activer' : 'de désactiver'} cet
                            service. Voulez vous vraiment continuer ?</p>

                        <div className="text-center">
                            <Button className="my-4" color="primary" type="button"
                                    onClick={setStatus}>Valider</Button>
                        </div>
                    </div>
                }
                toggle={toggleModal}
                backdrop={true}
            />
        </>
    )
}
