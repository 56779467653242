import { Card, CardHeader, Row, Table } from "reactstrap";
import { ServiceManagement } from "./ServiceManagement";
import { TransactionDetails } from "./TransactionDetails";
import { Transaction } from "types/transaction";
import { TransformUtils } from "main/utils/transformUtils";
import { DEFAULT_IMAGE } from "constants/index";

type Props = {
  transactions: Transaction[];
};

export const Transactions = ({ transactions }: Props) => {
  return (
    <>
      <Card className="shadow">
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">Toutes mes transactions</h3>
            </div>
            <div className="col text-right">
              <ServiceManagement />
            </div>
          </Row>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Service</th>
              <th scope="col">Destinataire</th>
              <th scope="col">Montant (XOF)</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.id}>
                <th scope="row">
                  {TransformUtils.getDate(transaction.createdAt!)}
                </th>
                <th scope="row">
                  <div className="d-flex align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="icon"
                        src={transaction.service!.icon ?? DEFAULT_IMAGE}
                      />
                    </span>
                    &nbsp;
                    <span>{transaction.service!.name}</span>
                  </div>
                </th>
                <th scope="row">
                  <div className="d-flex align-items-center flex-row">
                  <span className="avatar avatar-sm mr-1" style={{backgroundColor: "#FFFFFF", borderRadius:"0"}}>
                    <img alt="country" src={transaction.client!.country} />
                  </span>
                  <span>
                  <a className="pointer text-primary">
                    {transaction.client!.phoneNumber}
                  </a>
                </span>
                  </div>
                
                 
                </th>
                <th scope="row">{transaction.amount}</th>
                <td className="text-default">
                  <TransactionDetails transaction={transaction} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </>
  );
};
