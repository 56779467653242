// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Select from "main/shared/controls/Select";
import {
  Transaction,
  TransactionFilterRequest,
  TransactionStatusFilter, transactionStatusFilterOptions,
} from "types/transaction";
import { Page } from "types/page";
import { Seller } from "types/seller";
import { pageSize } from "constants/index";
import { TransformUtils } from "main/utils/transformUtils";

type Props = {
  transactions?: Page<Transaction>;
  sellers: Seller[];
  onFilter: (criteria: TransactionFilterRequest) => void;
};

export const TransactionBoardFilter = ({
  transactions,
  onFilter,
}: Props) => {
  // const filterBySeller = (value: string) => {
  //   const sellerId = sellers.find((seller) => seller.phoneNumber === value)?.id;

  //   if (sellerId) {
  //     const filter: TransactionFilterRequest = {
  //       criteriaType: "SELLER",
  //       value: sellerId,
  //     };

  //     onFilter(filter);
  //   }
  // };

  const filterByStatus = (status: TransactionStatusFilter) => {
    const request: TransactionFilterRequest = {
      criteriaType: "STATUS",
      value: status,
    };

    onFilter(request);
  };

  const filterByClient = (event: React.ChangeEvent<HTMLInputElement>) => {
    const request: TransactionFilterRequest = {
      criteriaType: "CLIENT",
      value: event.target.value,
    };

    onFilter(request);
  };

  const filterByDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const request: TransactionFilterRequest = {
      criteriaType: "DATE",
      value: event.target.value,
    };

    onFilter(request);
  };

  const filterBySize = (status: TransactionStatusFilter) => {
    const request: TransactionFilterRequest = {
      criteriaType: "SIZE",
      value: status,
    };

    onFilter(request);
  };

  return (
    <div className="header bg-gradient-info pb-3 pb-md-4 pt-4 pt-md-4">
      <Container fluid>
        <div className="header-body">
          <Row>
            <Col>
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col-12">
                      <Form>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <Label
                                for="sellerId"
                                className="font-weight-bold"
                              >
                                <i className="fa fa-phone" /> Rechercher par
                                numéro
                              </Label>
                              <InputGroup className="input-group-alternative">
                                <InputGroupText>
                                  <i className="fa fa-phone" />
                                </InputGroupText>
                                <Input
                                  placeholder="Rensigner le numéro"
                                  type="text"
                                  name="phoneNumber"
                                  onChange={filterByClient}
                                />
                              </InputGroup>
                              {/* <AutocompleteInput
                                  name="sellerId"
                                  suggestions={sellers.map(
                                    (s) => s.phoneNumber
                                  )}
                                  placeholder="Enter le numéro"
                                  onSuggestionSelected={
                                    filterBySeller
                                  }
                                /> */}
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label for="status" className="font-weight-bold">
                                <i className="fa fa-level-up" />
                                Status
                              </Label>
                              <Select
                                name="status"
                                id="status"
                                isMulti={false}
                                data={transactionStatusFilterOptions.map((status) => {
                                  return {
                                    value: status.value,
                                    label: (
                                      <span>
                                        <i className={status.className} /> {status.label}
                                      </span>
                                    ),
                                  };
                                })}
                                onChangeHandler={(e, a) => {
                                  filterByStatus(e.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label for="date" className="font-weight-bold">
                                <i className="fa fa-calendar" /> Rechercher par
                                date
                              </Label>
                              <Input
                                placeholder="Search"
                                id="date"
                                isMulti={false}
                                type="date"
                                max={TransformUtils.getCurrentDate(new Date())}
                                onChange={(e) => filterByDate(e)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label for="size" className="font-weight-bold">
                                <i className="fa fa-globe" />
                                Total éléments
                              </Label>
                              <Select
                                name="size"
                                id="size"
                                isMulti={false}
                                data={pageSize}
                                onChangeHandler={(e, a) => {
                                  filterBySize(e.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <p className="text-right text-primary">
                          <span>
                            Total transactions : {transactions?.totalElements}
                          </span>
                        </p>
                      </Form>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default TransactionBoardFilter;
