import { useState } from "react";
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroupText,
    InputGroup,
  } from "reactstrap";
import { UserCredentials } from "types/user";
  
type Props = {
    onSubmit: (credentials: UserCredentials, toggleLoading: Function) => void;
}

const LoginForm = ({onSubmit}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [credentials, setCredentials] = useState<UserCredentials>({
        username:'',
        password: ''
    });
    const toggleLoading = () => {
        setLoading(prev => !prev);
    }

    const formIsValid = !!credentials.username && !!credentials.password;
    const disabled = !formIsValid || loading;

    const onFormChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;
        setCredentials({...credentials, [name] : value });
    }

    const onFormSubmitted = () => {
        toggleLoading();
        onSubmit(credentials, toggleLoading);
    }

    return (
        <Form role="form">
            <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                    <InputGroupText><i className="ni ni-email-83" /></InputGroupText>
                    <Input placeholder="Email" 
                        type="email" 
                        name="username"
                        value={credentials.username} 
                        onChange={onFormChange}
                        />
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <InputGroup className="input-group-alternative">
                    <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
                    <Input placeholder="Mot de passe" type="password" autoComplete="off"
                        name="password"
                        value={credentials.password} 
                        onChange={onFormChange}
                        />
                </InputGroup>
            </FormGroup>
            <div className="custom-control custom-control-alternative custom-checkbox">
                <input className="custom-control-input" id=" customCheckLogin" type="checkbox"/>
                <label className="custom-control-label" htmlFor=" customCheckLogin">
                <span>Se souvenir de moi</span>
                </label>
            </div>
            <div className="text-center">
                <Button className="my-4 px-5" color="primary" type="button" 
                    onClick={onFormSubmitted} 
                    disabled={disabled}>Se connecter</Button>
            </div>
        </Form>
    )
}

export default LoginForm;