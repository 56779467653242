import {endpoints} from "constants/endpoints";
import {performRequest} from "api/http";
import {HttpRequestParameters} from "types/http";
import { useState} from "react";
import {reloadWindows} from "../constants";
import {PENDING_MESSAGE} from "../constants/toast";
import { Provider, ProviderChangeStatus, ProviderCreationRequest, ProviderFilterRequest, ProviderUpdateRequest } from "types/provider";
import { isActive, Service, ServiceCreationRequest, ServiceLight, ServiceUpdateRequest, ServiceChangeStatus, ServiceFilterRequest } from "types/service";

export const useProviderHooks = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [providersFiltered, setProvidersFiltered] = useState<Provider[]>(providers);
    const [services, setServices] = useState<Service[]>([]);
    const [servicesLight, setServicesLight] = useState<ServiceLight[]>([]);
    const [servicesFiltered, setServicesFiltered] = useState<Service[]>(services);

    const getAllProviders = () => {
        setIsLoading(true);

        let params: HttpRequestParameters = {
            uri: endpoints.providers,
            method: "get",
            onSuccess: (response: Provider[]) => {
                setProviders(response);
            },
            data: null,
            successMessage: "",
            pendingMessage: ""
        }

        performRequest(params);
    }

    const saveProvider = (request: ProviderCreationRequest, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.providers,
            method: "post",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: "Opérateur enregistré avec succès",
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    const updateProvider = (request: ProviderUpdateRequest, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.providers,
            method: "patch",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: "Opérateur modifié avec succès",
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    const setProviderStatus = (request: ProviderChangeStatus, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.providers.concat("/set-status"),
            method: "patch",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: `Opérateur ${isActive(request.status) ? 'activé' : 'désactivé'} avec succès`,
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    const getAllServicesByProvider = (providerId: string) => {
        setIsLoading(true);

        let params: HttpRequestParameters = {
            uri: endpoints.providers.concat("/" +providerId + "/services"),
            method: "get",
            onSuccess: (response: Service[]) => {
                setServices(response);
            },
            data: null,
            successMessage: "",
            pendingMessage: ""
        }

        performRequest(params);
    }

    const getAllServices = () => {
        setIsLoading(true);

        let params: HttpRequestParameters = {
            uri: endpoints.providers.concat("/services"),
            method: "get",
            onSuccess: (response: ServiceLight[]) => {
                setServicesLight(response);
            },
            data: null,
            successMessage: "",
            pendingMessage: ""
        }

        performRequest(params);
    }

    const saveService = (request: ServiceCreationRequest, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.providers.concat("/services/add"),
            method: "post",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: "Service ajouté avec succès",
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    const updateService = (request: ServiceUpdateRequest, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.providers.concat("/services/update"),
            method: "patch",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: "Service modifié avec succès",
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    const setServiceStatus = (request: ServiceChangeStatus, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.providers.concat("/services/set-status"),
            method: "patch",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: `Service ${isActive(request.status) ? 'activé' : 'désactivé'} avec succès`,
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    const filterProviders = (request: ProviderFilterRequest) => {
        switch (request.criteriaType) {            
            case "NAME":
                return setProvidersFiltered(providers.filter(provider => provider.name.toLowerCase().includes(request.value!.toLowerCase())));
            case "STATUS":
                if (request.value === 'ALL') {
                    return setProvidersFiltered(providers);
                }
                return setProvidersFiltered(providers.filter(provider => provider.status === request.value));
            case "TYPE":
                if (request.value === 'ALL') {
                    return setProvidersFiltered(providers);
                }
                return setProvidersFiltered(providers.filter(provider => provider.type === request.value));
            default:
                return providers;                
        }
    }

    const filterServices = (request: ServiceFilterRequest) => {
        switch (request.criteriaType) {            
            case "NAME":
                return setServicesFiltered(services.filter(service => service.name.toLowerCase().includes(request.value!.toLowerCase())));
            case "STATUS":
                if (request.value === 'ALL') {
                    return setServicesFiltered(services);
                }
                return setServicesFiltered(services.filter(service => service.status === request.value));
            default:
                return services;     
        }
    }


    return {
        isLoading,
        getAllProviders,
        providers,
        saveProvider,
        updateProvider,
        getAllServicesByProvider,
        services,
        getAllServices,
        servicesLight,
        setProviderStatus,
        saveService,
        updateService,
        setServiceStatus,
        filterProviders,
        providersFiltered,
        filterServices,
        servicesFiltered,
    };
}