import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { getProviderBadgeColor, Provider, translateProviderName } from "../../types/provider";
import { NewProvider } from "./NewProvider";
import { EditProvider } from "./EditProvider";
import { ChangeProviderStatus } from "./ChangeProviderStatus";
import { Link } from "react-router-dom";
import { DEFAULT_IMAGE } from "constants/index";

type Props = {
  providers: Provider[];
};

export const ProviderList = ({ providers }: Props) => {
  return (
    <>
      <Card className="shadow" style={{ height: "100%" }}>
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">Tous les opérateurs</h3>
            </div>
            <div className="col text-right">
              <NewProvider />
            </div>
          </Row>
        </CardHeader>

        <Container fluid>
          <Row>
            {providers.map((provider) => (
              <Col md="4" lg="4" key={provider.id}>
                <Card className="card-pointer mb-3" style={{ width: "200px" }}>
                  <span className="text-sm">
                    <Badge
                      className="text-white"
                      color={getProviderBadgeColor(provider.type)}
                    >
                      {translateProviderName(provider.type)}
                    </Badge>
                  </span>
                  <CardBody className="p-2">
                    <Link to={`/providers/services/${provider.id}`}>
                      <img
                        alt={provider.name?.substring(0, 2)}
                        src={provider.icon ?? DEFAULT_IMAGE}
                        className="w-100 h-100"
                      />
                    </Link>
                    <div className="d-flex align-items-center">
                      <span className="font-weight-bold my-2">
                        {provider.name}
                      </span>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-2">
                      <EditProvider provider={provider} />
                      <ChangeProviderStatus provider={provider} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Card>
    </>
  );
};
