import { UserCreationRequest, isSeller, userRoles } from "../../types/user";
import { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import Select from "../shared/controls/Select";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { countries } from "constants/countries";
import { getCountriesOptions } from "main/shared/common/Country";

type Props = {
  onSubmit: (user: UserCreationRequest, toggleLoading: Function) => void;
};

export const NewUserForm = ({ onSubmit }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const toggleLoading = () => {
    setLoading((prev) => !prev);
  };

  const [request, setRequest] = useState<UserCreationRequest>({});

  const isValidSeller =
    request.role !== "SELLER" ||
    (request.role === "SELLER" && !!request.address);

  const formIsValid =
    !!request.firstname &&
    !!request.lastname &&
    !!request.email &&
    !!request.phoneNumber &&
    !!request.role &&
    isValidSeller;

  const disabled = !formIsValid || loading;

  const onFormChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setRequest({ ...request, [name]: value });
  };

  const onFormSubmitted = () => {
    onSubmit(request, toggleLoading);
  };


  return (
    <Form role="form">
      <FormGroup className="mb-3">
        <Label className="font-weight-bold">
          Prénom <span className="text-danger font-weight-bold">*</span>
        </Label>
        <InputGroup className="input-group-alternative">
          <InputGroupText>
            <i className="fa fa-user" />
          </InputGroupText>
          <Input
            placeholder="Rensigner le prénom"
            type="text"
            name="firstname"
            value={request.firstname}
            required
            onChange={onFormChange}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="mb-3">
        <Label className="font-weight-bold">
          Nom <span className="text-danger font-weight-bold">*</span>
        </Label>
        <InputGroup className="input-group-alternative">
          <InputGroupText>
            <i className="fa fa-user" />
          </InputGroupText>
          <Input
            placeholder="Rensigner le nom"
            type="text"
            name="lastname"
            value={request.lastname}
            required
            onChange={onFormChange}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="mb-3">
        <Label className="font-weight-bold">
          Email <span className="text-danger font-weight-bold">*</span>
        </Label>
        <InputGroup className="input-group-alternative">
          <InputGroupText>
            <i className="fa fa-envelope" />
          </InputGroupText>
          <Input
            placeholder="Rensigner l'email"
            type="text"
            name="email"
            value={request.email}
            required
            onChange={onFormChange}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="mb-3">
        <Label className="font-weight-bold">
          Numéro de téléphone{" "}
          <span className="text-danger font-weight-bold">*</span>
        </Label>
        <PhoneInput
          placeholder="Rensigner le numéro de téléphone"
          value={request.phoneNumber}
          autoFocus
          defaultCountry="sn"
          name="phoneNumber"
          onChange={(value) =>
            setRequest({
              ...request,
              phoneNumber: value.length <= 8 ? undefined : value,
            })
          }
          required
        />
      </FormGroup>
      <FormGroup>
        <Label className="font-weight-bold">
          Role <span className="text-danger font-weight-bold">*</span>
        </Label>
        <Select
          name="role"
          id="role"
          isMulti={false}
          data={userRoles}
          required={true}
          onChangeHandler={(e, a) => {
            setRequest({ ...request, role: e.value });
          }}
        />
      </FormGroup>
      {isSeller(request.role!) && (
        <FormGroup className="mb-3">
          <Label className="font-weight-bold">
            Pays <span className="text-danger font-weight-bold">*</span>
          </Label>
          <Select
            name="address"
            id="address"
            isMulti={false}
            data={getCountriesOptions()}
            onChangeHandler={(e, a) => {
              setRequest({ ...request, address: e.value });
            }}
          />
        </FormGroup>
      )}
      <div className="text-center">
        <Button
          className="my-4 px-5"
          color="primary"
          type="button"
          onClick={onFormSubmitted}
          disabled={disabled}
        >
          Valider
        </Button>
      </div>
    </Form>
  );
};
