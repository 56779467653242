import Modal from '../shared/controls/CustomModal';
import {useState} from "react";
import {Button} from "reactstrap";
import {ToastContainer} from "react-toastify";
import {TOAST_WIDTH} from "../../constants/toast";
import {Services} from "./Services";

export const ServiceManagement = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);

    return (
        <>
            <div className="col text-right">
                <Button className="btn-sm" onClick={toggleModal}>
                    Gérer mes services <i className="fa text-success fa-plus-circle"/>
                </Button>
            </div>
            <Modal
                isOpen={isOpen}
                header={"Gestion des services"}
                body={
                    <div className="container">
                        <Services toggleModal={toggleModal}/>
                    </div>
                }
                toggle={toggleModal}
            />

            <ToastContainer style={{width: TOAST_WIDTH}}/>
        </>
    )
}