import { Button } from "reactstrap";
import {UserCreationRequest} from "../../types/user";
import Modal from "../shared/controls/CustomModal"
import {useState} from "react";
import {ToastContainer} from "react-toastify";
import {TOAST_WIDTH} from "../../constants/toast";
import {NewUserForm} from "./NewUserForm"
import { useUserHooks } from "hooks/UserHooks";
export const NewUser = () => {
   const { saveUser } = useUserHooks();

    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);

    const registerUser = (request: UserCreationRequest): void => {
        saveUser(request, toggleModal);
    }

    return (
        <>
            <Button className="btn-sm" onClick={toggleModal}>
                Nouveau utilisateur <i className="fa text-success fa-plus-circle"/>
            </Button>
            <Modal
                isOpen={isOpen}
                header={"Ajout d'un nouveau utilisateur"}
                body={
                    <div className="container">
                        <NewUserForm onSubmit={registerUser}/>
                    </div>
                }
                toggle={toggleModal}
            />

            <ToastContainer style={{width: TOAST_WIDTH}}/>

            {/*<UserAccessConfigForm*/}
            {/*    isOpen={modalConfigUser}*/}
            {/*    toggle={toggleModalConfigUser}*/}
            {/*   />*/}
        </>
    );
}