import {UserStatus} from "../../types/user";

export class TransformUtils {
    public static getInitialName(firstname?: string, lastname?: string): string {
        if (firstname == null || lastname == null) {
            return '';
        }
        return firstname.substring(0, 1).concat(lastname.substring(0, 1));
    }

    public static onSearch(event: React.ChangeEvent<HTMLInputElement>, hasValue: boolean, results: any[], getAll: () => void,)  {
        const value = event.target.value;
        if (value.length > 0) {
            return results;
        } else  {
            getAll();
        }
    }

    public static getStatusColor(status: UserStatus) {
        switch (status) {
            case 'ACTIVE':
                return 'success';
            case 'INACTIVE':
                return 'danger';
                case 'SUSPENDED':
                    return 'warning';
            case "DELETED":
                return 'danger';
        }
    }

    public static getServiceName(serviceName: string): string {
        const parsedName: string[] = serviceName.split(" ");

        if (parsedName.length > 1) {
            return parsedName[0].substring(0, 1).concat(parsedName[1].substring(0, 1)).toUpperCase();
        }
        return serviceName.substring(0, 2).toUpperCase();
    }

    public static getDate(date: string): string {
        return date.split('T')[0];
    }

    public static getCurrentDate(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }
    
}

