import Modal from "../shared/controls/CustomModal";
import { Button, UncontrolledTooltip } from "reactstrap";
import { UserChangeStatusRequest, UserInfo, isActive } from "../../types/user";
import { useUserHooks } from "hooks/UserHooks";
import { useState } from "react";

type Props = {
  user: UserInfo;
};
export const ChangeUserStatus = ({ user }: Props) => {
  const { setUserStatus } = useUserHooks();
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const setStatus = (): void => {
    const request: UserChangeStatusRequest = {
      userId: user.id!,
      status: isActive(user.status!) ? "INACTIVE" : "ACTIVE",
    };

    setUserStatus(user.role!, request, toggleModal);
  };

  console.log(user);

  return (
    <>
      <span id="change-user-status">
        <i
          className={`fa fa-user-plus text-sm mx-2 pointer
                    ${
                      !isActive(user.status!) ? "text-success" : "text-danger"
                    }`}
          onClick={toggleModal}
        ></i>
        <UncontrolledTooltip placement="bottom" target="change-user-status">
            Activer/Désactiver
        </UncontrolledTooltip>
      </span>
      <Modal
        isOpen={isOpen}
        header={
          !isActive(user.status!)
            ? "Activation utilisateur"
            : "Désactivation utilisateur"
        }
        body={
          <div className="container">
            <p>
              Vous êtes sur le point{" "}
              {!isActive(user.status!) ? "d'activer" : "de désactiver"} cet
              utilisateur. Voulez vous vraiment continuer ?
            </p>

            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="button"
                onClick={setStatus}
              >
                Valider
              </Button>
            </div>
          </div>
        }
        toggle={toggleModal}
      />
    </>
  );
};
