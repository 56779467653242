import dashboardConstants from "main/dashboard/constants";
import { Lang, TranslationWords } from "types/words";

const HOST  = "http://" + window.location.hostname.concat(":8084");

const API = (): string => HOST.concat("/api/bo");

const words: TranslationWords = { ...dashboardConstants };

const translate = (word:string, lang:Lang="en"): string => {
    return !!words[word] ? words[word][lang] ?? word : word;
}

const reloadWindows = () => {
    setTimeout(() => {
        window.location.reload()
    }, 2000)
}

const DEFAULT_IMAGE = "https://placehold.co/400x400";

const pageSize: Array<{
    value: number;
    label: string;
  }> = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 200, label: "200" }
  ];

export { HOST, API, translate, reloadWindows, DEFAULT_IMAGE, pageSize };

