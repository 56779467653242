import {
    Badge,
    Card,
    CardHeader,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Media,
    Row,
    Table,
    UncontrolledDropdown
} from "reactstrap"
import {TransformUtils} from "../utils/transformUtils";
import {isDeleted, isSeller, translateUserRole, UserInfo} from "../../types/user";
import UserEdit from "./UserEdit";
import {useState} from "react";
import {NewUser} from "./NewUser";
import { ChangeUserStatus } from "./ChangeUserStatus";
import { ResetUserPassword } from "./ResetUserPassword";
import { getCountryFlagUrl } from "main/shared/common/Country";

type Props = {
    users: UserInfo[];
}
export const Users = ({users}: Props) => {
    const [editModal, setEditModel] = useState(false);
    const toggleEditModal = ()=> setEditModel(!editModal);

    const [changeStatusModal, setChangeStatusModal] = useState(false);
    const toggleChangeStatusModal = ()=> setChangeStatusModal(!changeStatusModal);

    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const toggleResetPasswordModal = ()=> setResetPasswordModal(!resetPasswordModal);
    return (
        <>
            <Card className="shadow">
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Tous les utilisateuteurs</h3>
                        </div>
                        <div className="col text-right">
                            <NewUser/>
                        </div>
                    </Row>

                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th scope="col" className="">Nom complet</th>
                        <th scope="col" className="">Nom d'utilisateur</th>
                        <th scope="col" className="">Numéro de téléphone</th>
                        <th scope="col" className="">Pays</th>
                        <th scope="col" className="">Role</th>
                        <th scope="col" className="">Status</th>
                        <th scope="col" className="">Date dé création</th>
                        <th scope="col"/>
                    </tr>
                    </thead>
                    <tbody>
                    {users?.map((user) => (
                            <tr key={user.id}>
                                <th scope="row">
                                    <Media className="align-items-center">
                                          <span className="avatar rounded-circle mr-2 bg-success text-sm">
                                              <span
                                                  className="text-white text-sm">{TransformUtils.getInitialName(user.firstname!, user.lastname!)}</span>
                                          </span>
                                        <Media>
                                            <span className="mb-0 text-sm">{user.firstname} {user.lastname}</span>
                                        </Media>
                                    </Media>
                                </th>
                                <td className="">{user.email}</td>
                                <td className="">{user.phoneNumber}</td>
                                <td className="">
                                    {
                                        isSeller(user.role!) ?
                                        <span>
                                         <img 
                                           src={getCountryFlagUrl(user.address!) ?? ""} 
                                           alt={user.address} 
                                           width="16"
                                           height="12" 
                                           className="mr-2"
                                         /> 
                                         {user.address}
                                       </span>
                                       : <span>{""}</span>
                                    }
                                </td>
                                <td className="">{translateUserRole(user.role!)}</td>
                                <td>
                                    <Badge color="" className="badge-dot mr-4 display-4">
                                        <i className={`bg-${TransformUtils.getStatusColor(user.status!)}`}/>
                                        {user?.status}
                                    </Badge>
                                </td>
                                <td className="text-center">{TransformUtils.getDate(user.createdAt!)}</td>
                                <td className="d-flex align-items-center" style={{paddingTop: '2rem'}}>
                                    {
                                        !isDeleted(user) && (
                                            <>
                                                <UserEdit user={user} />
                                                <ChangeUserStatus user={user} />
                                                <ResetUserPassword user={user}/>
                                            </>
                                        )
                                    }

                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </Table>
            </Card>
        </>
    )
}