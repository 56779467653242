import {endpoints} from "constants/endpoints";
import {get, performRequest} from "api/http";
import {FetchParameters, HttpRequestParameters} from "types/http";
import { useState} from "react";
import {API, reloadWindows} from "../constants";
import {PENDING_MESSAGE} from "../constants/toast";
import { Page } from "types/page";
import { Seller } from "types/seller";
import {  ChangePasswordRequest, getUserStatusTextMessage, isSeller, ResetPasswordRequest, UserChangeStatusRequest, UserCreationRequest, UserFilterRequest, UserInfo, UserRole, UserUpdateRequest } from "types/user";
import { useAuthentication } from "./AuthenticationHooks";

export const useUserHooks = () => {
    const [sellers, setSellers] = useState<Seller[]>();
    const [users, setUsers] = useState<Page<UserInfo>>();
    const [isLoading, setIsLoading] = useState(false);
    const {setUserSession} = useAuthentication();

    const getSellers = () => {
        setIsLoading(true);

        let params: HttpRequestParameters = {
            uri: endpoints.users.concat("/sellers"),
            method: "get",
            onSuccess: (response: Seller[]) => {
                setSellers(response);
            },
            data: null,
            successMessage: "",
            pendingMessage: ""
        }

        performRequest(params);
    }

    const getAllUsers = (page: number, size: number) => {
        setIsLoading(true);

        let params: HttpRequestParameters = {
            uri: endpoints.users.concat("?page=", page.toString(), "&size=", size.toString()),
            method: "get",
            onSuccess: (response: Page<UserInfo>) => {
                setUsers(response);
            },
            data: null,
            successMessage: "",
            pendingMessage: ""
        }

        performRequest(params);
    }

    const saveUser = (request: UserCreationRequest, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.users.concat(isSeller(request.role!) ? "/seller" : ''),
            method: "post",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: "Utilisateur crée avec succès",
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    
    const updateUser = (request: UserUpdateRequest, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.users.concat(isSeller(request.role!) ? "/seller" : ''),
            method: "patch",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: "Utilisateur modifié avec succès",
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    const setUserStatus = (role: UserRole, request: UserChangeStatusRequest, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.users.concat(isSeller(role) ? "/seller/set-status" : '/set-status'),
            method: "patch",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: `Utilisateur ${getUserStatusTextMessage(request.status)} avec succès`,
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    const resetPassword = (request: ResetPasswordRequest, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.users.concat("/reset-password"),
            method: "patch",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: "Mot de passe réinitialisé avec succès",
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    const filterUsers = (request: UserFilterRequest, page: number, size: number) => {
        setIsLoading(true);

        if (request.criteriaType === "STATUS" && request.value === "ALL") {
            getAllUsers(page, size);
            return;
        }

        let params: HttpRequestParameters = {
            uri: endpoints.users.concat("/filter?page=", page.toString(), "&size=", size.toString()),
            method: "post",
            onSuccess: (response: Page<UserInfo>) => {
                setUsers(response);
            },
            data: request,
            successMessage: "",
            pendingMessage: ""
        }

        performRequest(params);
    }
    const changePassword = (request: ChangePasswordRequest, onDone: Function|null = null) => {
        let params: FetchParameters = {
            method: "patch", 
            url: `${API()}${endpoints.users}/change-password`,
            onSuccess: (response) => {
                setTimeout(() => {
                    setUserSession(response);
                    window.location.reload();
                }, 2000)
            },
            onDone,
            data: request,
        };

        get(params, "Vérification des informations....", "Mot de passe modifié avec succès", true)
    }

    return {
        isLoading,
        getSellers,
        sellers,
        getAllUsers,
        users,
        saveUser,
        updateUser,
        setUserStatus,
        resetPassword,
        filterUsers,
        changePassword
    };
}