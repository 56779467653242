import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"



type Props = {
    isOpen: boolean,
    header: any,
    body: any,
    toggle: () => void,
    backdrop?: boolean;
    size?: string;
}

const CustomModal = ({isOpen, header, body, toggle, backdrop = false, size = "md"}: Props) => {
    const closeBtn = (
        <button className="close" onClick={toggle} type="button">
            &times;
        </button>
    );
    return (
        <>
            <Modal isOpen={isOpen}
                   toggle={toggle}
                   centered={true}
                   backdrop={backdrop}
                   size={size}
                   autoFocus={false}>
                <ModalHeader toggle={toggle} close={closeBtn} className="pb-0">
                    <h3 className="text-center ml-3">{header}</h3>
                </ModalHeader>
                <ModalBody>
                    {body}
                </ModalBody>
            </Modal>
        </>
    )
}

export default CustomModal