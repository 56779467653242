import { countries } from "constants/countries";

type Option = {
    value: string;
    label: React.ReactNode; 
}
  
const FLAG_BASE_URL: string = "https://flagcdn.com/16x12";

export const getCountriesOptions = (): Option[] => {
    return countries.map((country) => ({
      value: country.name,
      label: (
        <span>
          <img 
            src={`${FLAG_BASE_URL}/${country.alpha2Code.toLowerCase()}.png`} 
            alt={country.name} 
            width="16"
            height="12" 
            className="mr-2"
          /> 
          {country.name}
        </span>
      ),
    }));
  };

  export const getCountryFlagUrl = (countryName: string): string => {
    const country = countries.find((c) => c.name === countryName);
    return `${FLAG_BASE_URL}/${country?.alpha2Code.toLowerCase()}.png`;
  };