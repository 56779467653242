import { useState } from "react";
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroupText,
    InputGroup,
  } from "reactstrap";
import { ChangePasswordRequest } from "types/user";

type Props = {
    onSubmit: (request: ChangePasswordRequest, toggleLoading: Function) => void;
}

export const ChangePasswordForm = ({onSubmit}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [request, setRequest] = useState<ChangePasswordRequest>({newPassword: '', confirmPassword:''});
    const formIsValid = !!request.newPassword && !!request.confirmPassword;
    const passwordNotMatch: boolean = request.newPassword !== request.confirmPassword;
    const disabled = !formIsValid || passwordNotMatch || loading;

    const toggleLoading = () => {
        setLoading(prev => !prev);
    }

    const onFormChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;
        setRequest({...request, [name] : value });
    }

    const onFormSubmitted = () => {
        toggleLoading();
        onSubmit(request, toggleLoading);
    }

    return (
        <Form role="form">
            <FormGroup>
                <InputGroup className="input-group-alternative">
                    <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
                    <Input placeholder="Nouveau mot de passe" type="password" autoComplete="off"
                        name="newPassword"
                        value={request.newPassword}
                        onChange={onFormChange}
                        />
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <InputGroup className="input-group-alternative">
                    <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
                    <Input placeholder="Confirmer votre nouveau mot de passe" type="password" autoComplete="off"
                        name="confirmPassword"
                        value={request.confirmPassword}
                        onChange={onFormChange}
                        />
                </InputGroup>
            </FormGroup>
            <div className="text-center">
                <Button className="my-4 px-5" color="primary" type="button" 
                    onClick={onFormSubmitted} 
                    disabled={disabled}>Valider</Button>
            </div>
        </Form>
    )
}
