import { useUserHooks } from "hooks/UserHooks";
import Modal from '../shared/controls/CustomModal';

import { ResetPasswordRequest, UserInfo } from "types/user";
import { Button, UncontrolledTooltip } from "reactstrap";
import { useState } from "react";

type Props = {
    user: UserInfo
}

export const ResetUserPassword = ({ user}: Props) => {
    const { resetPassword } = useUserHooks();
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);
    const reset = (): void => {
        const request: ResetPasswordRequest = {
            accountId: user.accountId!,
        }

        resetPassword(request, toggleModal);
    }

    return (
        <>
            <span id="reset-user-password">
                <i className={'fa fa-key text-sm text-warning pointer'}  onClick={toggleModal}></i>
                <UncontrolledTooltip placement="bottom" target="reset-user-password">Réinitilisation mot de passe</UncontrolledTooltip>
            </span>
            <Modal
                isOpen={isOpen}
                header={"Réinitilisation mot de passe"}
                body={
                    <div className="container">
                        <p>Vous êtes sur le point réinitiliser le mot de passe de {user.firstname} ${user.lastname}
                            utilisateur. Voulez vous vraiment continuer ?</p>

                        <div className="text-center">
                            <Button className="my-4" color="primary" type="button"
                                    onClick={reset}>Valider</Button>
                        </div>
                    </div>
                }
                toggle={toggleModal}
            />
        </>
    )
}