import { useState } from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Label,
} from "reactstrap";
import {
  isSeller,
  UserInfo,
  userRoles,
  UserUpdateRequest,
} from "../../types/user";
import Select from "../shared/controls/Select";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { getCountriesOptions } from "main/shared/common/Country";
import { countries } from "constants/countries";

type Props = {
  user: UserInfo;
  onSubmit: (user: UserUpdateRequest, toggleLoading: Function) => void;
};

const UserEditForm = ({ user, onSubmit }: Props) => {
  const [request, setRequest] = useState<UserUpdateRequest>({
    userId: user.id!,
    firstname: user.firstname!,
    lastname: user.lastname!,
    phoneNumber: user.phoneNumber!,
    role: user.role!,
    address: user.address,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const getCurrentCountry = () => {
    return getCountriesOptions().find((country) => country.value.toLowerCase() === request.address?.toLocaleLowerCase());
  }

  const onFormChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setRequest({ ...request, [name]: value });
  };
  
  const toggleLoading = () => {
    setLoading((prev) => !prev);
  };

  const onFormSubmitted = () => {
    onSubmit(request, toggleLoading);
  };

  return (
    <Form role="form">
      <FormGroup className="mb-3">
        <Label className="font-weight-bold">
          Prénom <span className="text-danger font-weight-bold">*</span>
        </Label>
        <InputGroup className="input-group-alternative">
          <InputGroupText>
            <i className="fa fa-user" />
          </InputGroupText>
          <Input
            placeholder="Prénom"
            type="text"
            name="firstname"
            value={request.firstname}
            onChange={onFormChange}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="mb-3">
        <Label className="font-weight-bold">
          Nom <span className="text-danger font-weight-bold">*</span>
        </Label>
        <InputGroup className="input-group-alternative">
          <InputGroupText>
            <i className="fa fa-user" />
          </InputGroupText>
          <Input
            placeholder="Nom"
            type="text"
            name="lastname"
            value={request.lastname}
            onChange={onFormChange}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="mb-3">
        <Label className="font-weight-bold">
          Numéro de téléphone{" "}
          <span className="text-danger font-weight-bold">*</span>
        </Label>
        <PhoneInput
          placeholder="Rensigner le numéro de téléphone"
          value={request.phoneNumber}
          autoFocus
          defaultCountry="sn"
          name="phoneNumber"
          onChange={(value) =>
            setRequest({
              ...request,
              phoneNumber: value.length <= 8 ? request.phoneNumber : value,
            })
          }
          required
        />
      </FormGroup>

      {isSeller(request.role!) && (
        <FormGroup className="mb-3">
          <Label className="font-weight-bold">
            Pays <span className="text-danger font-weight-bold">*</span>
          </Label>
            <Select
            name="address"
            id="address"
            isMulti={false}
            value={getCurrentCountry()}
            data={getCountriesOptions()}
            onChangeHandler={(e, a) => {
              setRequest({ ...request, address: e.value });
            }}
            />
        </FormGroup>
      )}

      {!isSeller(user.role!) && (
        <FormGroup>
          <Label className="font-weight-bold">
            Role <span className="text-danger font-weight-bold">*</span>
          </Label>
          <Select
            name="role"
            id="role"
            isMulti={false}
            value={userRoles.find((role) => role.value === request.role)}
            data={userRoles}
            onChangeHandler={(e, a) => {
              setRequest({...request, role: e.value});
            }}
          />
        </FormGroup>
      )}

      <div className="text-center">
        <Button
          className="my-4 px-5"
          color="primary"
          type="button"
          onClick={onFormSubmitted}
        >
          Valider
        </Button>
      </div>
    </Form>
  );
};

export default UserEditForm;
