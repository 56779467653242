import {useState} from "react";
import {getProviderBadgeColor, Provider} from "../../types/provider";
import {Badge, Button} from "reactstrap";
import Modal from "../shared/controls/CustomModal";
import {ToastContainer} from "react-toastify";
import {TOAST_WIDTH} from "../../constants/toast";
import {NewServiceForm} from "./NewServiceForm";
import {EditServiceForm} from "./EditServiceForm";
import {Service, ServiceUpdateRequest} from "../../types/service";
import { useProviderHooks } from "hooks/ProviderHooks";

type Props = {
    service: Service
}

export const EditService = ({service}: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);

    const { updateService } = useProviderHooks();

    const update = (request: ServiceUpdateRequest): void => {
        updateService(request, toggleModal);
    }

    return (
        <>
            <i className="fa fa-edit mr-3 text-primary pointer" onClick={toggleModal}></i>
            <Modal
                isOpen={isOpen}
                header="Edition service"
                body={
                    <div className="container">
                        <EditServiceForm service={service} onSubmit={update}/>
                    </div>
                }
                toggle={toggleModal}
                size={"md"}
            />
            <ToastContainer style={{width: TOAST_WIDTH}}/>
        </>
    )
}