import {useState} from "react";
import {Button} from "reactstrap";
import Modal from '../shared/controls/CustomModal';
import {ToastContainer} from "react-toastify";
import {TOAST_WIDTH} from "../../constants/toast";
import {NewProviderForm} from "./NewProviderForm";
import {ProviderCreationRequest} from "../../types/provider";
import { useProviderHooks } from "hooks/ProviderHooks";

export const NewProvider = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);

    const { saveProvider } = useProviderHooks();

    const addNewProvider = (request: ProviderCreationRequest): void => {
        saveProvider(request, toggleModal);
     }

    return (
        <>
            <div className="col text-right">
                <Button className="btn-sm" onClick={toggleModal}>
                    Nouveau opérateur <i className="fa text-success fa-plus-circle" />
                </Button>
            </div>
            <Modal
                isOpen={isOpen}
                header="Nouveau opérateur"
                body={
                    <div className="container">
                        <NewProviderForm onSubmit={addNewProvider}/>
                    </div>
                }
                toggle={toggleModal}
                size={"md"}
            />
            <ToastContainer style={{width: TOAST_WIDTH}}/>
        </>
    )
}