import { useUserHooks } from "hooks/UserHooks";
import AutocompleteInput from "main/shared/controls/AutocompleteInput";
import { useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {Deposit, DepositFilterRequest, DepositStatusFilter, depositStatusFilterOptions} from "types/deposit";
import { Page } from "types/page";
import Select from "main/shared/controls/Select";

type Props = {
  deposits?: Page<Deposit>;
  onFilter: (filter: DepositFilterRequest) => void;
};

const DepositBoardFilter = ({ deposits, onFilter }: Props) => {
  const { getSellers, sellers } = useUserHooks();

  useEffect(() => {
    getSellers();
  }, []);

  const filterBySeller = (value: string) => {
    const sellerId = sellers?.find((seller) => seller.phoneNumber === value)?.id;

    if (sellerId) {
      const request: DepositFilterRequest = {
        criteriaType: "SELLER",
        value: sellerId
      } 

      onFilter(request);
    }
  };

  const filterByStatus = (status: DepositStatusFilter) => {
    const request: DepositFilterRequest = {
      criteriaType: "STATUS",
      value: status
    }

    onFilter(request);
  }

  const filterByDate = (event:  React.ChangeEvent<HTMLInputElement>) => {
    const request: DepositFilterRequest = {
      criteriaType: "DATE",
      value: event.target.value
    }

    onFilter(request);
  }

  return (
    <div className="header bg-gradient-info pb-3 pb-md-4 pt-4 pt-md-4">
      <Container fluid>
        <div className="header-body">
          <Row>
            <Col>
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col-12">
                      <Form>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label for="seller" className="font-weight-bold">
                                <i className="fa fa-phone" /> Rechercher par
                                vendeur
                              </Label>
                              <AutocompleteInput
                                name="seller"
                                suggestions={
                                  sellers?.map(
                                    (seller) => seller.phoneNumber
                                  ) ?? []
                                }
                                placeholder="Enter le numéro"
                                onSuggestionSelected={filterBySeller}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label for="status" className="font-weight-bold">
                                <i className="fa fa-level-up" />
                                Status
                              </Label>
                              <Select
                                name="status"
                                id="status"
                                isMulti={false}
                                data={depositStatusFilterOptions.map((status) => {
                                  return {
                                    value: status.value,
                                    label: (
                                      <span>
                                        <i className={status.className} /> {status.label}
                                      </span>
                                    ),
                                  };
                                })}
                                onChangeHandler={(e, a) => {
                                  filterByStatus(e.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label for="date" className="font-weight-bold">
                                <i className="fa fa-calendar" /> Rechercher par
                                date
                              </Label>
                              <Input
                                placeholder="Search"
                                id="date"
                                isMulti={false}
                                type="date"
                                onChange={(e) => filterByDate(e)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <p className="text-right text-primary">
                          <span>
                            Total deposits: {deposits?.totalElements ?? 0}
                          </span>
                        </p>
                      </Form>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default DepositBoardFilter;
