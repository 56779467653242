import { isExpired, decodeToken } from "react-jwt";
import { UserRole, UserToken } from "types/user";

class TokenService {
  private tokenKey: string = "sbs-token";
  private storage = sessionStorage;
  private getStorageItem(key: string): string | null{
    return this.storage.getItem(key);
  }

  private setStorageItem(key: string, data: string): void {
    return this.storage.setItem(key, data);
  }

  getToken(): string | null {
    return this.getStorageItem(this.tokenKey)
  }
  setUserToken(token: string | null): void {
    if(token){
      this.setStorageItem(this.tokenKey, token);
    }
  }

  private get decodeToken(): UserToken | null {
    const token = this.getToken();
    return decodeToken(token!);
  }

  get accountId(): string {
    return this.decodeToken?.uuid!;
  }

  get userId(): string {
    return this.decodeToken?.userId!;
  }

  get userRole(): UserRole {
    return this.decodeToken?.role!;
  }
  
  private isExpiredToken(): boolean {
    const token = this.getToken();
    if (token == null) {
      return true;
    }
    return isExpired(token);
  }

  isAuthenticate(): boolean {
    return this.getToken() != null && !this.isExpiredToken();
  }
  
  isFirstLogin(): boolean {
    return this.decodeToken?.isFirstLogin ?? false;
  }
}

export default new TokenService();