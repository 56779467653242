export type Deposit = {
    id?: string
    amount?: number;
    firstname?: string;
    lastname?: string;
    sellerId: string;
    phoneNumber?: string;
    date?: string;
    status: DepositStatus;
}


export type DepositSubmitRequest = {
    amount?: number;
    sellerId?: string;
}

export type DepositCancelRequest = {
   depositId: string;
}

export type DepositStatus = 'SENT' | 'CANCELED';

export type DepositStatusFilter = 'ALL' | DepositStatus;

export type DepositFilterRequest = {
    criteriaType?: DepositFilterCriteriaType;
    value?: string;
}

export type DepositFilterCriteriaType = 'SELLER' | 'STATUS' | 'DATE';
export function getDepositStatusTextColor(status: DepositStatus) {
    switch (status) {
        case "SENT" :
            return "text-success";
        case "CANCELED" :
            return "text-danger";
    }
}

export function translateDepositStatus(status: DepositStatus) {
    switch (status) {
        case "SENT" :
            return "Envoyé";
        case "CANCELED" :
            return "Annulé";
    }
}

export function isSent(deposit: Deposit) {
    return deposit.status === 'SENT';
}

export const depositStatusFilterOptions: Array<{
    value: DepositStatusFilter;
    label: string;
    className: string;
}> = [
    { value: "ALL", label: "Tout", className: "fa fa-arrow-up" },
    {
        value: "SENT",
        label: "Envoyé",
        className: "fa fa-arrow-up text-primary",
    },
    {
        value: "CANCELED",
        label: "Annulé",
        className: "fa fa-arrow-down text-danger",
    },
];

