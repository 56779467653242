export type Service = {
    id: string;
    name: string;
    status: ServiceStatus;
    providerId: string;
    providerName: string;
    providerIcon: string;
    createdAt: string;
    updatedAt: string;
}

export type ServiceStatus = 'ACTIVE' | 'INACTIVE';

export type ServiceStatusFilter = 'ALL' | ServiceStatus;

export type ServiceLight = {
    id: string;
    name: string;
    status: ServiceStatus;
    providerIcon: string;
}

export type ServiceCreationRequest = {
    name?: string;
    providerId: string;
}

export type ServiceUpdateRequest = {
    serviceId: string;
    name: string;
}

export type ServiceChangeStatus = {
    serviceId: string;
    status: ServiceStatus;
}

export type ServiceFilterRequest = {
    criteriaType?: ServiceFilterCriteriaType;
    value?: string;
}

export type ServiceFilterCriteriaType = 'NAME' | 'STATUS';


export const serviceStatusFilterOptions: Array<{value: ServiceStatusFilter, label: string, className: string}> = [
    { value: "ALL", label: "Tout", className: "fa fa-arrow-up text-default" },
    { value: "ACTIVE", label: "Active", className: "fa fa-arrow-up text-success" },
    { value:  "INACTIVE", label: "Inactive", className: "fa fa-arrow-down text-danger" },
];

export function isActive(status: ServiceStatus) {
    return status === "ACTIVE";
}