import {endpoints} from "constants/endpoints";
import {performRequest} from "api/http";
import {HttpRequestParameters} from "types/http";
import { useState} from "react";
import {reloadWindows} from "../constants";
import {PENDING_MESSAGE} from "../constants/toast";
import { Deposit, DepositCancelRequest, DepositFilterRequest, DepositSubmitRequest } from "types/deposit";
import { Page } from "types/page";

export const useDepositHooks = () => {
    const [deposits, setDeposits] = useState<Page<Deposit>>();
    const [isLoading, setIsLoading] = useState(false);

    const getAllDeposits = (page: number, size: number) => {
        setIsLoading(true);

        let params: HttpRequestParameters = {
            uri: endpoints.deposits.concat("?page=", page.toString(), "&size=", size.toString()),
            method: "get",
            onSuccess: (response: Page<Deposit>) => {
                setDeposits(response);
            },
            data: null,
            successMessage: "",
            pendingMessage: ""
        }

        performRequest(params);
    }

    const saveDeposit = (request: DepositSubmitRequest, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.deposits,
            method: "post",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: "Deposit fait avec succès",
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    const cancelDeposit = (request: DepositCancelRequest, onCloseModal: () => void) => {
        let params: HttpRequestParameters = {
            uri: endpoints.deposits,
            method: "patch",
            onSuccess: (response) => {
                onCloseModal();
                reloadWindows()
            },
            data: request,
            successMessage: "Deposit annulé avec succès",
            pendingMessage: PENDING_MESSAGE,
            notify: true
        }

        performRequest(params);
    }

    const filterDeposits = (request: DepositFilterRequest, page: number, size: number) => {
        setIsLoading(true);

        if (request.criteriaType === "STATUS" && request.value === "ALL") {
            getAllDeposits(page, size);
            return;
        }

        let params: HttpRequestParameters = {
            uri: endpoints.deposits.concat("/filter?page=", page.toString(), "&size=", size.toString()),
            method: "post",
            onSuccess: (response: Page<Deposit>) => {
                setDeposits(response);
            },
            data: request,
            successMessage: "",
            pendingMessage: ""
        }

        performRequest(params);
    }

    return {
        isLoading,
        getAllDeposits,
        deposits,
        saveDeposit,
        cancelDeposit,
        filterDeposits
    };
}