import Modal from "../shared/controls/CustomModal";
import { Badge, Button } from "reactstrap";
import { useState } from "react";
import {
  isActive,
  Provider,
  ProviderChangeStatus,
  ProviderUpdateRequest,
} from "../../types/provider";
import { useProviderHooks } from "hooks/ProviderHooks";

type Props = {
  provider: Provider;
};
export const ChangeProviderStatus = ({ provider }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const { setProviderStatus } = useProviderHooks();

  const setStatus = (): void => {
    const request: ProviderChangeStatus = {
      providerId: provider.id!,
      status: isActive(provider) ? "INACTIVE" : "ACTIVE",
    };

    setProviderStatus(request, toggleModal);
  };

  return (
    <>
      <span className="text-sm">
        <Badge
          className="text-white"
          color={isActive(provider) ? "danger" : "success"}
         onClick={toggleModal}
            
        >
           { isActive(provider) ? "Désactiver" : "Activer" }
        </Badge>
      </span>
      <Modal
        isOpen={isOpen}
        header={
          !isActive(provider)
            ? "Activation opérateur "
            : "Désactivation opérateur " + provider.name
        }
        body={
          <div className="container">
            <p>
              Vous êtes sur le point{" "}
              {!isActive(provider) ? "d'activer" : "de désactiver"} cet
              opérateur. <br />
              <em>
                {isActive(provider) ? 'La désactivation': 'L\'activation'} de l'opérateur entrainera aussi celle de ces
                services.
              </em>
              <br />
              Voulez vous vraiment continuer ?
            </p>

            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="button"
                onClick={setStatus}
              >
                Valider
              </Button>
            </div>
          </div>
        }
        toggle={toggleModal}
        backdrop={true}
      />
    </>
  );
};
