import {useState} from "react";
import {Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row} from "reactstrap";
import {ServiceCreationRequest} from "../../types/service";
import {useParams} from "react-router";

type Props = {
    onSubmit: (request: ServiceCreationRequest, toggleLoading: Function) => void;
}
export const NewServiceForm = ({onSubmit}: Props) => {
    const {providerId} = useParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [request, setRequest] = useState<ServiceCreationRequest>({
        providerId: providerId!,
    });
    const formIsValid = !!request.name;
    const disabled = !formIsValid || loading;

    const toggleLoading = () => {
        setLoading(prev => !prev);
    }

    const onFormChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;
        setRequest({...request, [name] : value });
    }
    
    const onFormSubmitted = () => {
        onSubmit(request, toggleLoading);
    }

    return (
        <Form role="form">
            <Row className="mb-3">
                <Col lg="10">
                    <FormGroup className="mb-3">
                        <Label className="font-weight-bold">Nom <span className="text-danger font-weight-bold">*</span></Label>
                        <InputGroup className="input-group-alternative">
                            <InputGroupText><i className="fa fa-edit font-weight-bold"/></InputGroupText>
                            <Input placeholder="Renseigner le nom"
                                   type="text"
                                   name="name"
                                   value={request.name}
                                   required
                                   onChange={onFormChange}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
            <div className="text-center">
                <Button className="my-4 px-5" color="primary" type="button"
                        onClick={onFormSubmitted}
                        disabled={disabled}>Ajouter</Button>
            </div>
        </Form>
    );

}