import { TransformUtils } from "main/utils/transformUtils";
import { Badge, Button, Media, Table } from "reactstrap";
import {
  getTransactionButtonColor,
  isPending,
  Transaction,
  translateTransactionStatus,
} from "types/transaction";

type Props = {
  transaction: Transaction;
  treatTransaction: () => void;
  cancelTransaction: () => void
};

export const ClientDetails = ({ transaction, treatTransaction, cancelTransaction }: Props) => {
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="">
          <tr>
            <th scope="col">Nom Complet</th>
            <th scope="col">N° Téléphone</th>
            <th scope="col">Montant (XOF)</th>
            {isPending(transaction) ? (
              <th scope="col" />
            ) : (
              <>
                <th scope="col">Status</th>
                <th scope="col">Par</th>
                <th scope="col">Le</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          <tr key={transaction.id}>
            <th scope="row">
              <Media className="align-items-center">
                <span className="avatar rounded-circle mr-2 bg-primary text-sm">
                  <span className="text-white text-sm">
                    {TransformUtils.getInitialName(
                      transaction.client?.firstname,
                      transaction.client?.lastname
                    )}
                  </span>
                </span>
                <Media>
                  <span className="mb-0 text-sm">
                    {transaction.client?.firstname} {transaction.client?.lastname}
                  </span>
                </Media>
              </Media>
            </th>
            <th scope="row">{transaction.client?.phoneNumber}</th>
            <th scope="row" className="text-center">
              {transaction.amount}
            </th>
            {isPending(transaction) ? (
              <>
                <td>
                  <Button
                    color={getTransactionButtonColor("PENDING")}
                    onClick={treatTransaction}
                    size="sm"
                  >
                    {translateTransactionStatus("PENDING")}
                  </Button>
                  <Button
                    color={getTransactionButtonColor("CANCELED")}
                    onClick={cancelTransaction}
                    size="sm"
                  >
                    Annuler
                  </Button>
                </td>
              </>
            ) : (
              <>
                <td>
                  <span className="text-sm">
                    <Badge
                      className="text-white"
                      color={getTransactionButtonColor(transaction.status!)}
                    >
                      {translateTransactionStatus(transaction.status!)}
                    </Badge>
                  </span>
                </td>
                <th scope="row" className="text-center">
                  {transaction.treatedBy?.firstname}{" "}
                  {transaction.treatedBy?.lastname}
                </th>
                <th scope="row">
                  {TransformUtils.getDate(transaction.updatedAt!)}
                </th>
              </>
            )}
          </tr>
        </tbody>
      </Table>
    </>
  );
};
