import {useState} from "react";
import {Provider} from "../../types/provider";
import {Button} from "reactstrap";
import Modal from "../shared/controls/CustomModal";
import {ToastContainer} from "react-toastify";
import {TOAST_WIDTH} from "../../constants/toast";
import {NewServiceForm} from "./NewServiceForm";
import { useProviderHooks } from "hooks/ProviderHooks";
import { ServiceCreationRequest } from "types/service";

export const NewService = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);

    const { saveService } = useProviderHooks();

    const addNewService = (request: ServiceCreationRequest): void => {
        saveService(request, toggleModal);
    }

    return (
        <>
            <div className="col text-right">
                <Button className="btn-sm" onClick={toggleModal}>
                    Nouveau service <i className="fa text-success fa-plus-circle" />
                </Button>
            </div>
            <Modal
                isOpen={isOpen}
                header="Nouveau service"
                body={
                    <div className="container">
                        <NewServiceForm onSubmit={addNewService}/>
                    </div>
                }
                toggle={toggleModal}
                size={"md"}
            />
            <ToastContainer style={{width: TOAST_WIDTH}}/>
        </>
    )
}